export class Lavorazione{
    constructor(
        public IdAssistenza: number = 0,
        public PrezzoTotale: string = '',
        public Descrizione: string = null,
        public Note: string = null,
        public Foto: string = null,
        public Garanzia: number = 0,
        public IdLavorazione: number = 0
    ){}
}