export class Cliente {
    constructor(
        public Nome: string = '',
        public Cognome: string = '',
        public Telefono: string = '',
        public Cellulare: string = '',
        public Email: string = '',
        public Regione: number = 0,
        public Provincia: number = 0,
        public Comune: number = 0,
        public CAP: string = '',
        public Indirizzo: string = '',
        public Followup: number = 0,
        public Rating: number = 1,
        public IdCliente: number = 0,
        public FollowupRolex: number = 0,
      public FollowupTudor: number = 0,
      public LinkRolex: string = '',
      public LinkTudor: string = ''

    ) { }

    
}
