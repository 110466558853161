import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { colorSets } from '@swimlane/ngx-charts';
import { ApiService } from 'app/api.service';
import { Ordine } from 'app/models/ordine';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-insert-preordine',
  templateUrl: './insert-preordine.component.html',
  styleUrls: ['./insert-preordine.component.css']
})
export class InsertPreordineComponent implements OnInit {

  constructor(private service: ApiService) { }

  files: File[] = [];
  public dataaquisto: string;
  public chiamatoil: string;
  public datainserimento: string;
  public note: string;
  public stato: string;
  public acconto: string;
  public prezzo: string;
  public tipopag: string;
  public idorologio: number;
  public idorologio1: number = 0;
  public idorologio2: number = 0;
  public idorologio3: number = 0;

  public contId: number = 0;
  public id1 = false;
  public id2 = false;
  public id3 = false;

  public idcliente: number;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  onSelect(event: any) {
    this.files.push(event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);

  }
  acceptDataOrologio(data) {
    this.idorologio = data;
  }
  acceptDataOrologio1(data) {
    this.idorologio1 = data;
  }
  acceptDataOrologio2(data) {
    this.idorologio2 = data;
  }
  acceptDataOrologio3(data) {
    this.idorologio3 = data;
  }

  onAddOrologio(): void {
    switch (this.contId) {
      case 0: {
        this.id1 = true;
        break;
      }
      case 1: {
        this.id2 = true;
        break;
      }
      case 2: {
        this.id3 = true;
        break;
      }
    }
    this.contId = this.contId + 1;
  }

  acceptDataCliente(data) {
    this.idcliente = data;
  }
  handlePreordine(f: NgForm): void {


    let file = (this.files[0] ? this.files[0][0].name : '');
    let ordine = new Ordine(this.dataaquisto, this.chiamatoil, this.prezzo, this.datainserimento, this.note, this.acconto, this.tipopag, this.stato, file, this.idorologio, this.idorologio1, this.idorologio2, this.idorologio3, this.idcliente);
    $('#cover-spin').show();
    this.service.insertPreordine(ordine).subscribe({
      next: data => {
        let filesToUpload: File[] = this.files;
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
          return formData.append('file' + index, file[0], file[0].name);
        });

        this.service.uploadProperty(formData).subscribe({
          next: data => {
            $('#cover-spin').hide();
            Swal.fire({
              icon: 'success',
              title: 'Inserimento completato!',
              showConfirmButton: false,
              timer: 1500
            }).then((result) => {
              window.location.href = "/#/preordine";

            })

          },
          error: error => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Errore!'
            })
          }
        })


      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

  ngOnInit(): void {
  }

}
