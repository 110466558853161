import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";


import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { DataTablesModule } from 'angular-datatables';
// import { HomeComponent } from './pages/home/home.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { NgxDropzoneModule } from 'ngx-dropzone';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgSelect2Module } from 'ng-select2';
import { OrdiniComponent } from './pages/ordini/ordini.component';
import { OrdiniAggiungiComponent } from './pages/ordini/ordini-aggiungi/ordini-aggiungi.component';
import { OrdiniModificaComponent } from './pages/ordini/ordini-modifica/ordini-modifica.component';
import { VoltaStampaComponent } from './pages/Bolle/volta-stampa/volta-stampa.component';
import { VoltaStampaInsertComponent } from './pages/Bolle/volta-stampa-insert/volta-stampa-insert.component';
import { VoltaStampaDetailsComponent } from './pages/Bolle/volta-stampa-details/volta-stampa-details.component';
import { VoltaStampaNegozioComponent } from './pages/assistenza/volta-stampa-negozio/volta-stampa-negozio.component';
import { VoltaStampaNegozioInsertComponent } from './pages/assistenza/volta-stampa-negozio-insert/volta-stampa-negozio-insert.component';
import { VoltaStampaNegozioDetailsComponent } from './pages/assistenza/volta-stampa-negozio-details/volta-stampa-negozio-details.component';
import { ProposteComponent } from './pages/affitti/proposte/proposte.component';
import { ProposteAggiungiComponent } from './pages/affitti/proposte-aggiungi/proposte-aggiungi.component';
import { ProposteModificaComponent } from './pages/affitti/proposte-modifica/proposte-modifica.component';
import { RichiesteComponent } from './pages/affitti/richieste/richieste.component';
import { RichiesteAggiungiComponent } from './pages/affitti/richieste-aggiungi/richieste-aggiungi.component';
import { RichiesteModificaComponent } from './pages/affitti/richieste-modifica/richieste-modifica.component';
import { AcquirentiComponent } from './pages/compravendite/acquirenti/acquirenti.component';
import { AcquirentiAggiungiComponent } from './pages/compravendite/acquirenti-aggiungi/acquirenti-aggiungi.component';
import { AcquirentiModificaComponent } from './pages/compravendite/acquirenti-modifica/acquirenti-modifica.component';
import { VenditoriComponent } from './pages/compravendite/venditori/venditori.component';
import { VenditoriAggiungiComponent } from './pages/compravendite/venditori-aggiungi/venditori-aggiungi.component';
import { VenditoriModificaComponent } from './pages/compravendite/venditori-modifica/venditori-modifica.component';
import { ImmobiliComponent } from './pages/immobili/immobili.component';
import { ImmobiliAggiungiComponent } from './pages/immobili/immobili-aggiungi/immobili-aggiungi.component';
import { ImmobiliModificaComponent } from './pages/immobili/immobili-modifica/immobili-modifica.component';
import { ClienteComponent } from './pages/cliente/cliente.component';
import { ClientiComponent } from './pages/clienti/clienti.component';
import { InsertClientiComponent } from './pages/clienti/insert-clienti/insert-clienti.component';
import { OrologioComponent } from './pages/orologio/orologio.component';
import { OrologiComponent } from './pages/orologi/orologi.component';
import { InsertOrologiComponent } from './pages/orologi/insert-orologi/insert-orologi.component';
import { PreordineComponent } from './pages/preordine/preordine.component';
import { InsertPreordineComponent } from './pages/preordine/insert-preordine/insert-preordine.component';
import { StoricoComponent } from './pages/storico/storico.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { AssistenzeComponent } from './pages/assistenze/assistenze.component';
import { InsertAssistenzeComponent } from './pages/assistenze/insert-assistenze/insert-assistenze.component';
import { UpdateAssistenzeComponent } from './pages/assistenze/update-assistenze/update-assistenze.component';
import { UpdatePreordineComponent } from './pages/preordine/update-preordine/update-preordine.component';
import { UpdateClientiComponent } from './pages/clienti/update-clienti/update-clienti.component';



FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  timeGridPlugin
  
]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    CalendarComponent,
    ProfileComponent,
    HomepageComponent,
    NavbarComponent,
    OrdiniComponent,
    OrdiniAggiungiComponent,
    OrdiniModificaComponent,
    VoltaStampaComponent,
    VoltaStampaInsertComponent,
    VoltaStampaDetailsComponent,
    VoltaStampaNegozioComponent,
    VoltaStampaNegozioInsertComponent,
    VoltaStampaNegozioDetailsComponent,

    AppComponent,
    LoginComponent,
    RegisterComponent,
    CalendarComponent,
    ProfileComponent,
    HomepageComponent,
    NavbarComponent,
    ProposteComponent,
    ProposteAggiungiComponent,
    ProposteModificaComponent,
    RichiesteComponent,
    RichiesteAggiungiComponent,
    RichiesteModificaComponent,
    AcquirentiComponent,
    AcquirentiAggiungiComponent,
    AcquirentiModificaComponent,
    VenditoriComponent,
    VenditoriAggiungiComponent,
    VenditoriModificaComponent,
    ImmobiliComponent,
    ImmobiliAggiungiComponent,
    ImmobiliModificaComponent,
    OrdiniComponent,
    OrdiniAggiungiComponent,
    OrdiniModificaComponent,
    VoltaStampaComponent,
    VoltaStampaInsertComponent,
    VoltaStampaDetailsComponent,
    ClienteComponent,
    ClientiComponent,
    InsertClientiComponent,
    OrologioComponent,
    OrologiComponent,
    InsertOrologiComponent,
    PreordineComponent,
    InsertPreordineComponent,
    StoricoComponent,
    AssistenzeComponent,
    InsertAssistenzeComponent,
    UpdateAssistenzeComponent,
    UpdatePreordineComponent,
    UpdateClientiComponent
  ],
  imports: [
    NgbCollapseModule,
    NgSelect2Module,
    NgxDropzoneModule,
    FullCalendarModule,
    DpDatePickerModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    DataTablesModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    ToastrModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts,
    }),
    NgxChartsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
