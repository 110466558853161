import { Component, OnInit, EventEmitter,Output,Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ApiService } from 'app/api.service';
import { data } from 'jquery';
import { Observable, Subject } from 'rxjs';

import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';
import { Orologio } from 'app/models/orologio';
@Component({
  selector: 'app-orologio',
  templateUrl: './orologio.component.html',
  styleUrls: ['./orologio.component.css']
})
export class OrologioComponent implements OnInit {
  @Output() IdOrologio = new EventEmitter<number>();
  @Input() ID: number;

  Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2500, timerProgressBar: true })
  orologio$;
  public added: boolean = false;
  private searchTerms = new Subject<string>();

  public marca: string;
  public modello: string;
  public referenza: string;
  public matricola: string;
  public descrizione: string;
  public link: string;
  public id: number;
  public Data: any;

  constructor(private _service: ApiService) { }

  search(term: string): void {
    this.searchTerms.next(term);
  }
  public handleOrologio(f: NgForm): void {
    if (!this.added) {
      let orologio = new Orologio( this.marca, this.modello,this.referenza,this.matricola, this.descrizione, this.link );
      this._service.insertOrologio(orologio).subscribe({
        next: data => { this.Toast.fire({ icon: 'success', title: 'Inserimento completato!' }); this.id = data[0].ID; this.IdOrologio.emit(this.id); },
        error: error => {  }
      })
      this.added = true;
    } else {
      let orologio = new Orologio(this.marca, this.modello,this.referenza,this.matricola, this.descrizione, this.link, this.id);
      this._service.updateOrologio(orologio).subscribe({
        next: data => { this.Toast.fire({ icon: 'success', title: 'Aggiornamento completato!' }) },
        error: error => {  }
      })
    }
  }

  getOrologio(id: number): void {
    this._service.getOrologioById({ IdOrologio: id }).subscribe(data => {
      this.Data = data;
      let d = this.Data[0];
      this.added = true;
      this.marca = d.Marca;
      this.modello = d.Modello;
      this.referenza = d.Referenza;
      this.descrizione = d.Descrizione;
      this.link = d.Link;
      this.matricola = d.Matricola;
      this.id = d.IdOrologio;
      this.IdOrologio.emit(this.id);
    })

  }

  ngOnInit(): void {
    // this.orologio$ = this.searchTerms.pipe(
    //   distinctUntilChanged(),
    //   switchMap((term: string) => this._service.getOrologioStartingWith({ Nome: term })),
    // );

    if(this.ID){
      this.getOrologio(this.ID)
    }
  }

}
