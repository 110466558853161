import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-clienti',
  templateUrl: './update-clienti.component.html',
  styleUrls: ['./update-clienti.component.css']
})
export class UpdateClientiComponent implements OnInit {

  public idcliente;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.idcliente = params['id'];
    });
   }

  ngOnInit(): void {
  }

}
