import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { ApiService } from 'app/api.service';

@Component({
  selector: 'app-volta-stampa-insert',
  templateUrl: './volta-stampa-insert.component.html',
  styleUrls: ['./volta-stampa-insert.component.css']
})
export class VoltaStampaInsertComponent implements OnInit {
  closeModal: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private service: ApiService, private modalService: NgbModal) { }
  Data: any;
  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.loadArticoli();
  }

 
  NuovaStampa(): void {
    var codice = $("#codice").val();
    var numero = $("#numero").val();
    var dat = new Date($("#data").val().toString() != '' ? $("#data").val().toString() : new Date());
    dat.setHours(dat.getHours() + 1);
    var IntNominat = $("#IntNominat").val();
    var IntCodice = $("#IntCodice").val();
    var IntTel = $("#IntTel").val();
    var IntIndirizzo = $("#IntIndirizzo").val();
    var IntCellulare = $("#IntCellulare").val();
    var IntCAP = $("#IntCAP").val();
    var IntComune = $("#IntComune").val();
    var IntProvincia = $("#IntProvincia").val();
    var IntFAX = $("#IntFAX").val();
    var IntNazione = $("#IntNazione").val();
    var IntCodfis = $("#IntCodfis").val();
    var IntPiva = $("#IntPiva").val();
    var IntEmail = $("#IntEmail").val();
    var DestNominativo = $("#DestNominativo").val();
    var DestSede = $("#DestSede").val();
    var DestIndirizzo = $("#DestIndirizzo").val();
    var DestCAP = $("#DestCAP").val();
    var DestComune = $("#DestComune").val();
    var DestProvincia = $("#DestProvincia").val();
    var DestNazione = $("#DestNazione").val();
    var TrasCausale = $("#TrasCausale").val();
    var TrasAspetto = $("#TrasAspetto").val();
    var TrasMezzo = $("#TrasMezzo").val();
    var TrasResa = $("#TrasResa").val();
    var TrasData = new Date($("#TrasData").val().toString() != '' ? $("#TrasData").val().toString() : new Date());
    TrasData.setHours(TrasData.getHours() + 1);
    var TrasColli = $("#TrasColli").val();
    var TrasPeso = $("#TrasPeso").val();
    var TrasVettore = $("#TrasVettore").val();
    var Note = $("#Note").val();
    var Tn = $("#Tn").val();
    var ValoreAssicurato = $("#ValoreAssicurato").val();

    //if ($("#IntNominat").val().toString().toUpperCase() == 'VOLTA') {
    //  $("#IntCodice").val() = '00065';
    //  $("#IntIndirizzo").val() = 'VIA ANGELO FILIPPETTI 9';
    //  $("#IntCAP").val() = '20122';
    //  $("#IntComune").val() = 'MILANO';
    //  $("#IntProvincia").val() = 'MI';
    //  $("#IntNazione").val() = 'ITALIA';
    //  $("#IntPiva").val() = '00749080156';



    //}


    if ($("#IntNominat").val().toString().toUpperCase() == 'ROLEX') {
      IntNominat = 'ROLEX ITALIA SPA';
      IntCodice = '00065';
      IntIndirizzo = 'VIA ANGELO FILIPPETTI 9';
      IntCAP = '20122';
      IntComune = 'MILANO';
      IntProvincia = 'MI';
      IntNazione = 'ITALIA';
      IntPiva = '00749080156';
    }
    if ($("#IntNominat").val().toString().toUpperCase() == 'BREITLING') {
      IntNominat = 'BRWITLING ITALIA SRL';
      IntCodice = '00097';
      IntIndirizzo = 'VIA DELLA MOSCOVA 3';
      IntCAP = '20121';
      IntComune = 'MILANO';
      IntProvincia = 'MI';
      IntNazione = 'ITALIA';
      IntPiva = '';
    }
    if ($("#IntNominat").val().toString().toUpperCase() == 'RICHEMONT') {
      IntNominat = 'RICHEMONT';
      IntCodice = '00031';
      IntIndirizzo = 'VIA BENIGNO CRESPI 21';
      IntCAP = '20159';
      IntComune = 'MILANO';
      IntProvincia = 'MI';
      IntNazione = 'ITALIA';
      IntPiva = '';
    }

    if ($("#IntNominat").val().toString().toUpperCase() == 'TAG') {
      IntNominat = 'TAG HEUER';
      IntCodice = '00036';
      IntIndirizzo = 'VIA LORENZIN 4';
      IntCAP = '';
      IntComune = 'MILANO';
      IntProvincia = 'MI';
      IntNazione = 'ITALIA';
      IntPiva = '';
    }

    if ($("#IntNominat").val().toString().toUpperCase() == 'LVMH') {
      IntNominat = 'LVMH';
      IntCodice = '00085';
      IntIndirizzo = 'VIA LORENZINI 4';
      IntCAP = '29139';
      IntComune = 'MILANO';
      IntProvincia = 'MI';
      IntNazione = 'ITALIA';
      IntPiva = '';
    }

    if ($("#IntNominat").val().toString().toUpperCase() == 'LU') {
      IntNominat = 'LU.CA. PASINI GIOIELLI SRL';
      IntCodice = '00015';
      IntTel = '03041139';
      IntIndirizzo = 'VIA ZANDELLI 15A';
      IntCAP = '25121';
      IntComune = 'BRESCIA';
      IntProvincia = 'BS';
      IntNazione = 'ITALIA';
      IntPiva = '02587620986';
      IntEmail = 'INFO@PASINIGIOIELLI.COM';
    }
    // var pass = true;
    // $(".req").each(function (index: any, value: any) {
    //   if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
    //     $(value).addClass("rederror");
    //     pass = false;
    //   } else {
    //     $(value).removeClass("rederror");
    //   }
    // });
    // if (pass != true) {
    //   this.Toast.fire({
    //     icon: 'error',
    //     title: 'Devi compilare tutti i campi!'
    //   })
    //   return;
    // }

    let data = {
      Codice: codice,
      Numero: numero,
      Data: dat.toISOString(),
      IntestazioneNominativo: IntNominat,
      IntestazioneCodice: IntCodice,
      IntestazioneTelefono: IntTel,
      IntestazioneIndirizzo: IntIndirizzo,
      IntestazioneCellulare: IntCellulare,
      IntestazioneCAP: IntCAP,
      IntestazioneComune: IntComune,
      IntestazioneProvincia: IntProvincia,
      IntestazioneFax: IntFAX,
      IntestazionePiva: IntPiva,
      IntestazioneCodFis: IntCodfis,
      IntestazioneEmail: IntEmail,
      IntestazioneNazione: IntNazione,
      DestinazioneNominativo: DestNominativo,
      DestinazioneSede: DestSede,
      DestinazioneIndirizzo: DestIndirizzo,
      DestinazioneCAP: DestCAP,
      DestinazioneComune: DestComune,
      DestinazioneProvincia: DestProvincia,
      DestinazioneNazione: DestNazione,
      TrasportoCausale: TrasCausale,
      TrasportoAspetto: TrasAspetto,
      TrasportoMezzo: TrasMezzo,
      TrasportoResa: TrasResa,
      TrasportoData: TrasData.toISOString(),
      TrasportoColli: TrasColli,
      TrasportoPeso: TrasPeso,
      TrasportoVettore: TrasVettore,
      Note: Note,
      Tn: Tn,
      ValoreAssicurato: ValoreAssicurato
    }
    $('#cover-spin').show();
    this.service.insertStampa(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          window.location.href = "/#/negozio";

        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  NuovoArticolo(): void {
    var ArtCodice = $("#ArtCodice").val();
    var ArtCollezione = $("#ArtCollezione").val();
    var ArtLinea = $("#ArtLinea").val();
    var ArtReferenza = $("#ArtReferenza").val();
    var ArtDescrizione = $("#ArtDescrizione").val();
    var ArtUm = $("#ArtUm").val();
    var ArtQta = $("#ArtQta").val() == '' ? '1' : $("#ArtQta").val();
    var ArtCiva = $("#ArtCiva").val();
    var ArtAliq = $("#ArtAliq").val();
    var ArtAst = $("#ArtAst").val();


    let data = {
      Codice: ArtCodice,
      Collezione: ArtCollezione,
      Linea: ArtLinea,
      Referenza: ArtReferenza,
      Descrizione: ArtDescrizione,
      Um: ArtUm,
      Qta: ArtQta,
      AliqIva: ArtAliq,
      Civa: ArtCiva,
      Asterisco: ArtAst
    }
    $('#cover-spin').show();
    this.service.insertArticoloCopy(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.loadArticoli();
          this.modalService.dismissAll();

        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

  deleteArticolo(id): void {

    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo articolo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { IdArticolo: id };
        this.service.deleteArticoloCopy(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Elemento eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadArticoli();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  loadArticoli(): void {
    this.service.getArticoloCopy().subscribe({
      next: data => {
        this.Data = data;

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: error
        })
      }
    })
  }

}
