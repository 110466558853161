import { Routes } from '@angular/router';
import { AcquirentiComponent } from './pages/compravendite/acquirenti/acquirenti.component';
import { VenditoriComponent } from './pages/compravendite/venditori/venditori.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProposteComponent } from './pages/affitti/proposte/proposte.component';
import { RichiesteComponent } from './pages/affitti/richieste/richieste.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ImmobiliComponent } from './pages/immobili/immobili.component';
import { AcquirentiAggiungiComponent } from './pages/compravendite/acquirenti-aggiungi/acquirenti-aggiungi.component';
import { AcquirentiModificaComponent } from './pages/compravendite/acquirenti-modifica/acquirenti-modifica.component';
import { VenditoriAggiungiComponent } from './pages/compravendite/venditori-aggiungi/venditori-aggiungi.component';
import { VenditoriModificaComponent } from './pages/compravendite/venditori-modifica/venditori-modifica.component';
import { RichiesteAggiungiComponent } from './pages/affitti/richieste-aggiungi/richieste-aggiungi.component';
import { RichiesteModificaComponent } from './pages/affitti/richieste-modifica/richieste-modifica.component';
import { ProposteAggiungiComponent } from './pages/affitti/proposte-aggiungi/proposte-aggiungi.component';
import { ProposteModificaComponent } from './pages/affitti/proposte-modifica/proposte-modifica.component';
import { ImmobiliAggiungiComponent } from './pages/immobili/immobili-aggiungi/immobili-aggiungi.component';
import { ImmobiliModificaComponent } from './pages/immobili/immobili-modifica/immobili-modifica.component';
import { OrdiniComponent } from './pages/ordini/ordini.component';
import { OrdiniAggiungiComponent } from './pages/ordini/ordini-aggiungi/ordini-aggiungi.component';
import { OrdiniModificaComponent } from './pages/ordini/ordini-modifica/ordini-modifica.component';
import { VoltaStampaComponent } from './pages/Bolle/volta-stampa/volta-stampa.component';
import { VoltaStampaDetailsComponent } from './pages/Bolle/volta-stampa-details/volta-stampa-details.component';
import { VoltaStampaInsertComponent } from './pages/Bolle/volta-stampa-insert/volta-stampa-insert.component';


import { VoltaStampaNegozioComponent } from './pages/assistenza/volta-stampa-negozio/volta-stampa-negozio.component';
import { VoltaStampaNegozioInsertComponent } from './pages/assistenza/volta-stampa-negozio-insert/volta-stampa-negozio-insert.component';
import { VoltaStampaNegozioDetailsComponent } from './pages/assistenza/volta-stampa-negozio-details/volta-stampa-negozio-details.component';
import { StoricoComponent } from './pages/storico/storico.component';
// import { AssistenzeComponent } from './pages/assistenze/assistenze.component';
import { OrologiComponent } from './pages/orologi/orologi.component';
import { InsertOrologiComponent } from './pages/orologi/insert-orologi/insert-orologi.component';
import { ClientiComponent } from './pages/clienti/clienti.component';
import { InsertClientiComponent } from './pages/clienti/insert-clienti/insert-clienti.component';
import { PreordineComponent } from './pages/preordine/preordine.component';
import { InsertPreordineComponent } from './pages/preordine/insert-preordine/insert-preordine.component';
import { InsertAssistenzeComponent } from './pages/assistenze/insert-assistenze/insert-assistenze.component';
import { UpdateAssistenzeComponent } from './pages/assistenze/update-assistenze/update-assistenze.component';
import { AssistenzeComponent } from './pages/assistenze/assistenze.component';
import { UpdatePreordineComponent } from './pages/preordine/update-preordine/update-preordine.component';
import { UpdateClientiComponent } from './pages/clienti/update-clienti/update-clienti.component';
// import { InsertAssistenzeComponent } from './pages/assistenze/insert-assistenze/insert-assistenze.component';
// import { UpdateAssistenzeComponent } from './pages/assistenze/update-assistenze/update-assistenze.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'homepage',
    pathMatch: 'full',
  }, {
    path: 'homepage',
    component: HomepageComponent,
  }, {
    path: '',
    component: NavbarComponent,
    children: [
      {
        path: 'proposte',
        component: ProposteComponent
      },
      {
        path: 'proposte/aggiungi',
        component: ProposteAggiungiComponent
      },
      {
        path: 'proposte/modifica',
        component: ProposteModificaComponent
      },
      {
        path: 'venditori',
        component: VenditoriComponent
      },
      {
        path: 'venditori/aggiungi',
        component: VenditoriAggiungiComponent
      },
      {
        path: 'venditori/modifica',
        component: VenditoriModificaComponent
      },
      {
        path: 'ordini',
        component: OrdiniComponent
      },
      {
        path: 'ordini/aggiungi',
        component: OrdiniAggiungiComponent
      },
      {
        path: 'ordini/modifica',
        component: OrdiniModificaComponent
      },
      {
        path: 'richieste',
        component: RichiesteComponent
      },
      {
        path: 'richieste/aggiungi',
        component: RichiesteAggiungiComponent
      },
      {
        path: 'richieste/modifica',
        component: RichiesteModificaComponent
      },
      {
        path: 'acquirenti',
        component: AcquirentiComponent
      },
      {
        path: 'acquirenti/aggiungi',
        component: AcquirentiAggiungiComponent
      },
      {
        path: 'acquirenti/modifica',
        component: AcquirentiModificaComponent
      },
      {
        path: 'immobili',
        component: ImmobiliComponent
      },
      {
        path: 'immobili/aggiungi',
        component: ImmobiliAggiungiComponent
      },
      {
        path: 'immobili/modifica',
        component: ImmobiliModificaComponent
      },
      {
        path: 'negozio',
        component: VoltaStampaComponent
      },
      {
        path: 'negozio/aggiungi',
        component: VoltaStampaInsertComponent
      },
      {
        path: 'negozio/dettagli',
        component: VoltaStampaDetailsComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'calendar',
        component: CalendarComponent
      },
      {
        path: 'negoziostampa/dettagli',
        component: VoltaStampaNegozioDetailsComponent
      },

      {
        path: 'negoziostampa/aggiungi',
        component: VoltaStampaNegozioInsertComponent
      },

      {
        path: 'stampanegozio',
        component: VoltaStampaNegozioComponent
      },
      {
        path: 'preordine/aggiungi',
        component: InsertPreordineComponent
      },
      {
        path: 'preordine/modifica',
        component: UpdatePreordineComponent
      },

      {
        path: 'preordine',
        component: PreordineComponent
      },
      {
        path: 'clienti/aggiungi',
        component: InsertClientiComponent
      },
      {
        path: 'clienti/modifica',
        component: UpdateClientiComponent
      },

      {
        path: 'clienti',
        component: ClientiComponent
      },
      {
        path: 'orologi/aggiungi',
        component: InsertOrologiComponent
      },

      {
        path: 'orologi',
        component: OrologiComponent
      },
      {
        path: 'assistenze/aggiungi',
        component: InsertAssistenzeComponent
      },
      {
        path: 'assistenze/modifica',
        component: UpdateAssistenzeComponent
      },

      {
        path: 'assistenze',
        component: AssistenzeComponent
      },
      {
        path: 'storico',
        component: StoricoComponent
      },

    ]
  },
  {
    path: '**',
    redirectTo: 'homepage'
  }
]
