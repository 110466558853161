import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/api.service';
import { Assistenza } from 'app/models/assistenza';
import { Lavorazione } from 'app/models/lavorazione';
import { Intervento } from 'app/models/intervento';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-insert-assistenze',
  templateUrl: './insert-assistenze.component.html',
  styleUrls: ['./insert-assistenze.component.css']
})
export class InsertAssistenzeComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private service: ApiService, private modalService: NgbModal) { }
  Data: any;
  closeModal: any;
  files: File[] = [];

  //Assistenza
  public IdAssistenza: number;
  public IdCliente: number;
  public IdOrologio: number;
  public NumRiparaz: string;
  public NumRiferimento: string;
  public data: string = this.cutData(new Date().toISOString());
  public Stato: string = '';

  //Lavorazione
  public PrezzoTotale: number = 0;
  public Descrizione: string;
  public Note: string;
  //public Foto: string;
  public Garanzia:  string;


  //Intervento
  public Interventi: Intervento[] = [];
  public desc: string;
  public prezzo: number;
  public tipologia: string = '';

  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSelect(event: any) {
    this.files.push(event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit(): void {
    this.loadAssistenzaNums();
  }

  loadAssistenzaNums():void{
    this.service.getAssistenzaNums().subscribe(data =>{
      let d = data[0];
      let year:string = new Date().getFullYear().toString();
      if(d.NumRiparaz){
        let numrip: number = +d.NumRiparaz.split("/")[0];
        numrip++;
        var result:string = '';
        let length:number = numrip.toString().length;
        let cont = 0;
        for(let i=10;i>0;i--){
          if(i == length){
            result += numrip.toString()[cont];
            cont++;
            length--;
          }else{
            result += '0';
          }
        }
        this.NumRiparaz = result + "/" + year.substring(2,4);
      }else{
        this.NumRiparaz = '0000000001/' + year.substring(2,4);
      }

      if(d.NumRiferimento){
        let numrip: number = +d.NumRiferimento.split("/")[0];
        numrip++;
        var result:string = '';
        let length:number = numrip.toString().length;
        let cont = 0;
        for(let i=4;i>0;i--){
          if(i == length){
            result += numrip.toString()[cont];
            cont++;
            length--;
          }else{
            result += '0';
          }
        }
        this.NumRiferimento = result + "/" + year.substring(2,4);
      }else{
        this.NumRiferimento = '0001/' + year.substring(2,4);
      }
    })
  }

  acceptDataOrologio(data) {
    this.IdOrologio = data;
  }
  acceptDataCliente(data) {
    this.IdCliente = data;
  }
  handleAssistenza(f: NgForm): void{
    let assistenza = new Assistenza(this.IdCliente, this.IdOrologio, this.NumRiparaz, this.NumRiferimento, this.data, this.Stato);
    this.service.insertAssistenza(assistenza).subscribe({
      next: data => { this.Toast.fire({ icon: 'success', title: 'Inserimento completato!' }); this.IdAssistenza = data[0].ID; },
      error: error => { }
    })
  }

  handleLavorazione(f: NgForm): void{
    let file = (this.files[0] ? this.files[0][0].name : '');
    let gar:number = (this.Garanzia ? 1 : 0);
    let lavorazione = new Lavorazione(this.IdAssistenza, this.PrezzoTotale.toString(), this.Descrizione, this.Note, file, gar);
    $('#cover-spin').show();
    this.service.insertLavorazione(lavorazione).subscribe({
      next: data => { 
        let id = data[0].ID;
        this.Interventi.forEach(element => {
          element.IdLavorazione = id;
        });

        let filesToUpload: File[] = this.files;
        const formData = new FormData();
        this.service.insertIntervento(this.Interventi).subscribe();
        
        Array.from(filesToUpload).map((file, index) => {
          return formData.append('file' + index, file[0], file[0].name);
        });

        this.service.uploadProperty(formData).subscribe({
          next: data => {
            $('#cover-spin').hide();
            Swal.fire({
              icon: 'success',
              title: 'Inserimento completato!',
              showConfirmButton: false,
              timer: 1500
            }).then((result) => {
              this.modalService.dismissAll();
              this.loadLavorazioni();
            })

          },
          error: error => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Errore!'
            })
          }
        })

        
        
       },
      error: error => {  }
    })
  }

  loadLavorazioni(): void {
    this.service.getLavorazioneByIdAssistenza({IdAssistenza : this.IdAssistenza}).subscribe( data => this.Data = data );
  }

  deleteLavorazione(id): void {

    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo articolo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { idLavorazione: id };
        this.service.deleteLavorazione(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Elemento eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadLavorazioni();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  handleIntervento():void{
    this.Interventi.push(new Intervento(this.desc, this.prezzo, this.tipologia));
    this.PrezzoTotale += this.prezzo;
    this.desc = '';
    this.tipologia = '';
    this.prezzo = null;
  }

  deleteIntervento(i: number): void{
    this.PrezzoTotale -= this.Interventi[i].Prezzo;
    this.Interventi.splice(i, 1);
  }

  formatDecimal(n: number){
    return n.toFixed(2).replace(".",",");
  }

  cutData(data: string): string {
    let d = new Date(data);
    let result = d.toLocaleDateString().split("/")[2] + "-" + this.fixMonth(d.toLocaleDateString().split("/")[1]) + "-" + this.fixMonth(d.toLocaleDateString().split("/")[0]);
    return result;
  }
  fixMonth(m: string) {
    if (m.length == 1) {
      m = '0' + m;
    }
    return m;
  }

}
