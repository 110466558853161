import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-volta-stampa-details',
  templateUrl: './volta-stampa-details.component.html',
  styleUrls: ['./volta-stampa-details.component.css']
})
export class VoltaStampaDetailsComponent implements OnInit {
  closeModal: any;
  Data: any;
  Articoli: any;
  id: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  //stampaDoc(id): void {
  //  let data = { IdStampa: id };

  //  this.service.getStampaById(data).subscribe({
  //    next: data => {

  //      var ris = data[0];
  //      let d = ris["DestinazioneIndirizzo"] + '<br>' + ris["DestinazioneCAP"] + ' ' + ris["DestinazioneComune"] + ' ' + ris["DestinazioneProvincia"] + ', ' + ris["DestinazioneNazione"];
  //      $("#html-destinazione").html(d);
  //      d = ris["IntestazioneNominativo"] + '<br>' + ris["IntestazioneIndirizzo"] + '<br>' + ris["IntestazioneCAP"] + ' ' + ris["IntestazioneComune"] + ' ' + ris["IntestazioneProvincia"] + ', ' + ris["IntestazioneNazione"];
  //      $("#html-intestazione").html(d);
  //      $("#html-documento").html(ris["Codice"]);
  //      $("#html-numero").html(ris["Numero"]);
  //      let dat = new Date(ris["Data"])
  //      $("#html-data").html(dat.toLocaleDateString());

  //      if (ris["Tn"]) {
  //        $("#html-tn-box").removeClass("hidden");
  //        $("#html-tn").html(ris["Tn"]);
  //      }
  //      if (ris["ValoreAssicurato"]) {
  //        $("#html-val-box").removeClass("hidden");
  //        $("#html-valoreass").html(ris["ValoreAssicurato"]);
  //      }
  //      $("#html-causalet").html(ris["TrasportoCausale"]);
  //      $("#html-mezzot").html(ris["TrasportoMezzo"]);
  //      $("#html-aspettot").html(ris["TrasportoAspetto"]);
  //      $("#html-collit").html(ris["TrasportoColli"]);
  //      $("#html-pesot").html(ris["TrasportoPeso"]);
  //      let da = new Date(ris["TrasportoData"]);
  //      $("#html-datat").html(da.toLocaleDateString() + " " + da.toLocaleTimeString());
  //      $("#html-resat").html(ris["TrasportoResa"]);
  //      $("#html-vettoret").html(ris["TrasportoVettore"]);

  //      let codice = '';
  //      let ref = '';
  //      let desc = '';
  //      let qta = '';
  //      let tot = 0;
  //      $.each(data, function (index: any, value: any) {
  //        tot += parseInt(value["Qta"]);
  //        codice += '<p class="TableParagraph" style="margin-top: 6.15pt; margin-left: 2.2pt; font-size: 7.5pt"> <span>' + value["CodiceArticolo"] + '</span></p>';
  //        qta += '<p class="TableParagraph" style="margin-top: 6.15pt; margin-left: 25.6pt; font-size: 7.5pt"> <span>' + value["Qta"] + '</span><span style="letter-spacing: 0.35pt"> </span><span>Pz.</span></p>';
  //        ref += '<p class="TableParagraph" style="margin-left: 2.25pt; font-size: 7.5pt">' + value["ReferenzaArticolo"] + '</p>';
  //        desc += '<span style="letter-spacing: 0.45pt">' + value["DescrizioneArticolo"] + '</span><br>';
  //      });
  //      $("#html-art-codice").html(codice);
  //      $("#html-art-desc").html(desc);
  //      $("#html-art-ref").html(ref);
  //      $("#html-art-qta").html(qta);
  //      $("#html-art-tot").html(tot.toString());

  //      // stampa
  //      var printContents = document.getElementById("printPrevAssistenza").innerHTML;
  //      document.body.innerHTML = printContents;
  //      window.print();
  //      window.location.reload();

  //    },
  //    error: error => {
  //      $('#cover-spin').hide();
  //      this.Toast.fire({
  //        icon: 'error',
  //        title: 'Error!'
  //      })
  //    }
  //  })
  //}








  stampaDoc(id): void {
    let data = { IdStampa: id };

    this.service.getStampaById(data).subscribe({
      next: data => {

        var ris = data[0];
        let d = ris["DestinazioneIndirizzo"] + '<br> ' + ris["DestinazioneCAP"] + ' ' + ris["DestinazioneComune"] + ' ' + ris["DestinazioneProvincia"] + ', ' + ris["DestinazioneNazione"];
        $("#html-destinazione").html(d);
        d = ris["IntestazioneNominativo"] + '<br> ' + ris["IntestazioneIndirizzo"] + '<br> ' + ris["IntestazioneCAP"] + ' ' + ris["IntestazioneComune"] + ' ' + ris["IntestazioneProvincia"] + ', ' + ris["IntestazioneNazione"];
        $("#html-intestazione").html(d);
        $("#html-documento").html(ris["Codice"]);
        $("#html-numero").html(ris["Numero"]);
        let dat = new Date(ris["Data"])
        $("#html-data").html(dat.toLocaleDateString());

        if (ris["Tn"]) {
          $("#html-tn-box").removeClass("hidden");
          $("#html-tn").html(ris["Tn"]);
        }
        if (ris["ValoreAssicurato"]) {
          $("#html-val-box").removeClass("hidden");
          $("#html-valoreass").html(ris["ValoreAssicurato"]);
        }
        $("#html-causalet").html(ris["TrasportoCausale"]);
        $("#html-mezzot").html(ris["TrasportoMezzo"]);
        $("#html-aspettot").html(ris["TrasportoAspetto"]);
        $("#html-collit").html(ris["TrasportoColli"]);
        $("#html-pesot").html(ris["TrasportoPeso"]);
        let da = new Date(ris["TrasportoData"]);
        $("#html-datat").html(da.toLocaleDateString() + " " + da.toLocaleTimeString());
        $("#html-resat").html(ris["TrasportoResa"]);
        $("#html-vettoret").html(ris["TrasportoVettore"]);

        let codice = '';
        let ref = '';
        let desc = '';
        let qta = '';
        let tot = 0;
        $.each(data, function (index: any, value: any) {
          tot += parseInt(value["Qta"]);
          codice += '<p class="TableParagraph" style="margin-top: 6.15pt; margin-left: 2.2pt; "> <span>' + value["CodiceArticolo"] + '</span></p>';
          qta += '<p class="TableParagraph" style="margin-top: 6.15pt; margin-left: 25.6pt;"> <span>' + value["Qta"] + '</span><span style="letter-spacing: 0.35pt"> </span><span>Pz.</span></p>';
          ref += '<p class="TableParagraph" style="margin-left: 2.25pt; margin-top: 6.15pt;">' + value["ReferenzaArticolo"] + '</p>';
          desc += '<p class="TableParagraph" style="margin-left: 2.25pt; margin-top: 6.15pt;"><span style="letter-spacing: 0.45pt">' + value["DescrizioneArticolo"] + '</span></p>';
        });
        $("#html-art-codice").html(codice);
        $("#html-art-desc").html(desc);
        $("#html-art-ref").html(ref);
        $("#html-art-qta").html(qta);
        $("#html-art-tot").html(tot.toString());

        // stampa
        var printContents = document.getElementById("printPrevAssistenza").innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }
  
  constructor(private service: ApiService, private route: ActivatedRoute, private modalService: NgbModal) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.loadData();
    this.loadArticoli();
  }

  loadData():void{
    let data = { IdStampa: this.id };

    this.service.getStampaById(data).subscribe({
      next: data => {
        this.Data = data[0];
      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }

  loadArticoli(): void {
    let data = { IdStampa: this.id };
    this.service.getArticoliByStampaId(data).subscribe({
      next: data => {
        this.Articoli = data;

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: error
        })
      }
    })
  }

  AggiornaStampa(): void {
    var codice = $("#codice").val();
    var numero = $("#numero").val();
    var dat = new Date($("#data").val().toString() != '' ? $("#data").val().toString() : new Date() );
    dat.setHours(dat.getHours() + 1);
    var IntNominat = $("#IntNominat").val();
    var IntCodice = $("#IntCodice").val();
    var IntTel = $("#IntTel").val();
    var IntIndirizzo = $("#IntIndirizzo").val();
    var IntCellulare = $("#IntCellulare").val();
    var IntCAP = $("#IntCAP").val();
    var IntComune = $("#IntComune").val();
    var IntProvincia = $("#IntProvincia").val();
    var IntFAX = $("#IntFAX").val();
    var IntNazione = $("#IntNazione").val();
    var IntCodfis = $("#IntCodfis").val();
    var IntPiva = $("#IntPiva").val();
    var IntEmail = $("#IntEmail").val();
    var DestNominativo = $("#DestNominativo").val();
    var DestSede = $("#DestSede").val();
    var DestIndirizzo = $("#DestIndirizzo").val();
    var DestCAP = $("#DestCAP").val();
    var DestComune = $("#DestComune").val();
    var DestProvincia = $("#DestProvincia").val();
    var DestNazione = $("#DestNazione").val();
    var TrasCausale = $("#TrasCausale").val();
    var TrasAspetto = $("#TrasAspetto").val();
    var TrasMezzo = $("#TrasMezzo").val();
    var TrasResa = $("#TrasResa").val();
    var TrasData = new Date($("#TrasData").val().toString() != '' ? $("#TrasData").val().toString() : new Date() );
    TrasData.setHours(TrasData.getHours() + 1);
    var TrasColli = $("#TrasColli").val();
    var TrasPeso = $("#TrasPeso").val();
    var TrasVettore = $("#TrasVettore").val();
    var Note = $("#Note").val();
    var Tn = $("#Tn").val();
    var ValoreAssicurato = $("#ValoreAssicurato").val();

    // var pass = true;
    // $(".req").each(function (index: any, value: any) {
    //   if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
    //     $(value).addClass("rederror");
    //     pass = false;
    //   } else {
    //     $(value).removeClass("rederror");
    //   }
    // });
    // if (pass != true) {
    //   this.Toast.fire({
    //     icon: 'error',
    //     title: 'Devi compilare tutti i campi!'
    //   })
    //   return;
    // }

    let data = {
      Codice: codice,
      Numero: numero,
      Data: dat.toISOString(),
      IntestazioneNominativo: IntNominat,
      IntestazioneCodice: IntCodice,
      IntestazioneTelefono: IntTel,
      IntestazioneIndirizzo: IntIndirizzo,
      IntestazioneCellulare: IntCellulare,
      IntestazioneCAP: IntCAP,
      IntestazioneComune: IntComune,
      IntestazioneProvincia: IntProvincia,
      IntestazioneFax: IntFAX,
      IntestazionePiva: IntPiva,
      IntestazioneCodFis: IntCodfis,
      IntestazioneEmail: IntEmail,
      IntestazioneNazione: IntNazione,
      DestinazioneNominativo: DestNominativo,
      DestinazioneSede: DestSede,
      DestinazioneIndirizzo: DestIndirizzo,
      DestinazioneCAP: DestCAP,
      DestinazioneComune: DestComune,
      DestinazioneProvincia: DestProvincia,
      DestinazioneNazione: DestNazione,
      TrasportoCausale: TrasCausale,
      TrasportoAspetto: TrasAspetto,
      TrasportoMezzo: TrasMezzo,
      TrasportoResa: TrasResa,
      TrasportoData: TrasData.toISOString(),
      TrasportoColli: TrasColli,
      TrasportoPeso: TrasPeso,
      TrasportoVettore: TrasVettore,
      Note: Note,
      Tn: Tn,
      ValoreAssicurato: ValoreAssicurato,
      IdStampa : this.id
    }
    $('#cover-spin').show();
    this.service.updateStampa(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'success',
          title: 'Modidica effettuata con successo!'
        })


      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  NuovoArticolo(): void{
    var ArtCodice = $("#ArtCodice").val();
    var ArtCollezione = $("#ArtCollezione").val();
    var ArtLinea = $("#ArtLinea").val();
    var ArtReferenza = $("#ArtReferenza").val();
    var ArtDescrizione = $("#ArtDescrizione").val();
    var ArtUm = $("#ArtUm").val();
    var ArtQta = $("#ArtQta").val() == '' ? '1' : $("#ArtQta").val();
    var ArtCiva = $("#ArtCiva").val();
    var ArtAliq = $("#ArtAliq").val();
    var ArtAst = $("#ArtAst").val();
    

    let data = {
        Codice  : ArtCodice,
        Collezione  : ArtCollezione,
        Linea  : ArtLinea,
        Referenza  : ArtReferenza,
        Descrizione  : ArtDescrizione,
        Um  : ArtUm,
        Qta  : ArtQta,
        AliqIva  : ArtAliq,
        Civa : ArtCiva,
        Asterisco : ArtAst,
        IdStampa: this.id
    }
    $('#cover-spin').show();
    this.service.insertArticolo(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
         this.loadArticoli();
         this.modalService.dismissAll();

        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

  deleteArticolo(id): void{

    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo articolo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { IdArticolo : id };
        this.service.deleteArticolo(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Elemento eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadArticoli();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  //cutData(data:string):string{
  //  let d = new Date(data);
  //  return d.toISOString().split("T")[0];
  //}
  cutData(data: string): string {
    let d = new Date(data);
    let result = d.toLocaleDateString().split("/")[2] + "-" + this.fixMonth(d.toLocaleDateString().split("/")[1]) + "-" + this.fixMonth(d.toLocaleDateString().split("/")[0]);
    return result;
  }
  fixMonth(m: string) {
    if (m.length == 1) {
      m = '0' + m;
    }
    return m;
  }
  cutDatetime(data:string):string{
    let d = new Date(data);
    return d.toISOString().replace("T", " ");
  }

}
