export class Ordine {
    constructor(
        public DataAcquisto: string,
        public ChiamatoIl: string,
        public PrezzoFinale: string,
        public DataInserimento: string,
        public Note: string = '',
        public Acconto: string = '',
        public TipoPagamento: string = '',
        public Stato: string = '',
        public Allegato: string = '',
        public IdOrologio: number = 0,
        public IdOrologio1: number = 0,
        public IdOrologio2: number = 0,
        public IdOrologio3: number = 0,
        public IdCliente: number = 0,
        public IdOrdine: number = 0,
        public IdPreordine: number = 0

    ) { 
        if(!this.ChiamatoIl){
            let date = new Date();
            this.ChiamatoIl = date.toISOString();
        }
        if(!this.DataAcquisto){
            let date = new Date();
            this.DataAcquisto = date.toISOString();
        }
        if(!this.DataInserimento){
            let date = new Date();
            this.DataInserimento = date.toISOString();
        }
        if(!this.Acconto){
            this.Acconto = '0';
        }
        if(!this.PrezzoFinale){
            this.PrezzoFinale = '0';
        }
        if (!this.Allegato){
            this.Allegato = '';
        }
    }

    
}