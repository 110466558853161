import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { ApiService } from 'app/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private service: ApiService, private cookie: CookieService, private modalService: NgbModal) { }
  userdata;
  users;
  IdUser;
  closeModal: any;
  myStyle = {'color' : 'blue'};
  Admin: boolean;
  ngOnInit(): void {
    $('#cover-spin').show();

    this.loadData();

    var user = this.cookie.get("session");
    let data = { Hash: user }
    this.service.getUserByHash(data).subscribe({
      next: data => {
        this.userdata = data[0];
        this.IdUser = data[0].IdUser;
        this.Admin = (data[0].Role == 'Amministratore' ? true : false);
        $('#cover-spin').hide();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Impossible to load data!'
        })
      }
    });



  }


  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  loadData() {
    this.service.getUser().subscribe({
      next: data => {
        $(data).each(function (index: any, value: any) {
          value["Color"] = {'color' : value["Color"]};
        });
        this.users = data;

      },
      error: error => {
        $('#cover-spin').hide();

      }
    })

  }


  Update() {

    var nome = $("#nome").val();
    var cognome = $("#cognome").val();
    var tel = $("#tel").val();
    var email = $("#email").val();

    var pass = true;

    $(".req").each(function (index: any, value: any) {
      if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
        $(value).addClass("rederror");
        pass = false;
      } else {
        $(value).removeClass("rederror");
      }
    });

    if (pass != true) {
      this.Toast.fire({
        icon: 'error',
        title: 'Devi compilare tutti i campi!'
      })
      return;
    }

    let data = {
      Name: nome
      , Surname: cognome
      , Phone: tel
      , Email: email
      , IdUser: this.IdUser

    }


    $('#cover-spin').show();
    this.service.updateUser(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'success',
          title: 'Modifica effettuata con successo!'
        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  NewUser() {

    var nome = $("#nomeU").val();
    var cognome = $("#cognomeU").val();
    var tel = $("#telU").val();
    var email = $("#emailU").val();
    var psw = $("#psw").val();
    var conpsw = $("#conpsw").val();
    var color = $("#color").val();
    var role = $("#role option:selected").val();
    //color = "style='color: " + color + "'";

    if (psw != conpsw) {
      this.Toast.fire({
        icon: 'error',
        title: 'Le password non coincidono!'
      })
      return;
    }

    var pass = true;

    $(".reqU").each(function (index: any, value: any) {
      if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
        $(value).addClass("rederror");
        pass = false;
      } else {
        $(value).removeClass("rederror");
      }
    });

    if (pass != true) {
      this.Toast.fire({
        icon: 'error',
        title: 'Devi compilare tutti i campi!'
      })
      return;
    }

    let data = {
      Name: nome
      , Surname: cognome
      , Phone: tel
      , Email: email
      , Password: Md5.hashStr(psw.toString())
      , Username: (nome.toString() + cognome.toString())
      , Hash: Md5.hashStr(nome.toString() + cognome.toString())
      , Role: role,
      Color: color
    }


    $('#cover-spin').show();
    this.service.insertUser(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          text: 'Username: ' + nome.toString() + cognome.toString(),
          showConfirmButton: true,
        }).then((result) => {
          this.loadData();

        })
        this.modalService.dismissAll();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  deleteUser(id) {
    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo utente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { IdUser: id };
        this.service.deleteUser(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Utente eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadData();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  reableUser(id) {
    Swal.fire({
      title: 'Sei sicuro di attivare/disattivare questo utente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { IdUser: id };
        this.service.changeUser(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Stato modificato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadData();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  changePswUser(id) {
    Swal.fire({
      title: '<strong>Reset Password</strong>',
      html:
        `
        <input type="password" class="swal2-input" id="pssw" placeholder="Nuova Password">
        <input type="password" class="swal2-input" id="rpssw" placeholder="Ripeti Password">
          `,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText:
        'Modifica',
    }).then((result) => {
      if (result.isConfirmed) {
        let psw = $("#pssw").val();
        let rpsw = $("#rpssw").val();

        if (psw != rpsw || psw == '') {
          this.Toast.fire({
            icon: 'error',
            title: 'Le password non coincidono!'
          })
          return;
        }

        let data = { Password: Md5.hashStr(psw.toString()), Iduser: id };

        this.service.changePswUser(data).subscribe({
          next: data => {
            $('#cover-spin').hide();
            Swal.fire({
              icon: 'success',
              title: 'Password modificata correttamente!',
              showConfirmButton: false,
              timer: 2000
            })
            this.modalService.dismissAll();

          },
          error: error => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Errore!'
            })
          }
        })
      }
    })
  }
}
