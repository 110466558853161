import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/api.service';
import { Assistenza } from 'app/models/assistenza';
import { Lavorazione } from 'app/models/lavorazione';
import { Intervento } from 'app/models/intervento';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-assistenze',
  templateUrl: './update-assistenze.component.html',
  styleUrls: ['./update-assistenze.component.css']
})
export class UpdateAssistenzeComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private service: ApiService, private modalService: NgbModal, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.IdAssistenza = params['id'];
    });
  }

 
  Data: any;
  closeModal: any;
  files: File[] = [];

  public update: boolean = false;

  //Assistenza
  public IdAssistenza: number;
  public IdCliente: number;
  public IdOrologio: number;
  public NumRiparaz: string;
  public NumRiferimento: string;
  public data: string;
  public Stato: string;
  public isClosed: boolean = false;

  //Lavorazione
  public PrezzoTotale: number = 0;
  public Descrizione: string;
  public Note: string;
  //public Foto: string;
  public Garanzia: boolean;
  public IdLavorazione: number;


  //Intervento
  public Interventi: Intervento[] = [];
  public desc: string;
  public prezzo: number;
  public tipologia: string = '';

  //Stampa
  public PrevNumb: string;
  public Nome: string;
  public Cognome: string;
  public Tel: string;
  public Email: string;
  public Notes: string;
  public DataRic: string;
  public Referenza: string;
  public Matricola: string;
  public LavorazObb: Intervento[] = [];
  public LavorazOpz: Intervento[] = [];
  public totalprice: number = 0;

  public PrintMatricola: string;
  public PrintModel: string;
  public PrintDate: string;

  triggerModal(content: any) {
    this.clearModal();
    this.update = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  triggerEditModal(content: any, id: number) {
    this.clearModal();
    this.IdLavorazione = id;
    this.update = true;
    this.getLavorazioneById(id);
    this.loadInterventi(id);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSelect(event: any) {
    this.files.push(event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getAssistenzaById(id: number): void {
    this.service.getAssistenzaById({ IdAssistenza: id }).subscribe(data => {
      // this.Data = data;
      let d = data[0];
      this.IdCliente = d.IdCliente;
      this.IdOrologio = d.IdOrologio;
      this.NumRiparaz = d.NumRiparaz;
      this.NumRiferimento = d.NumRiferimento;
      this.data = this.cutData(d.Data);
      this.Stato = d.Stato;


    })
  }

  clearModal(): void {
    this.PrezzoTotale = 0;
    this.Descrizione = '';
    this.Note = '';
    this.Garanzia = false;
    this.Interventi = [];
  }

  getLavorazioneById(id: number): void {
    this.service.getLavorazioneById({ IdLavorazione: id }).subscribe(data => {
      // this.Data = data;
      let d = data[0];

      this.PrezzoTotale = d.PrezzoTotale;
      this.Descrizione = d.Descrizione;
      this.Note = d.Note;
      this.Garanzia = (d.Garanzia == 1 ? true : false);


    })
  }

  ngOnInit(): void {
    this.getAssistenzaById(this.IdAssistenza);
    this.loadLavorazioni();
    this.stampaDoc();
    this.stampaEpson();
    this.stampaVerde();
  }

  async handleAssistenza(): Promise<void> {
    let assistenza = new Assistenza(this.IdCliente, this.IdOrologio, this.NumRiparaz, this.NumRiferimento, this.data, this.Stato, this.IdAssistenza, this.isClosed);
    $('#cover-spin').show();
    await this.service.updateAssistenza(assistenza).subscribe({
      next: data => {
        this.Toast.fire({ icon: 'success', title: 'Aggiornamento completato!' });
        $('#cover-spin').hide();
      },
      error: error => {
        this.Toast.fire({ icon: 'error', title: 'Errore! verifica la correttezza della Email!' });
        $('#cover-spin').hide();
      }
    })
  }

  changeStato(e: any): void {
    this.isClosed = (e.target.value == 'Pronto' ? true : false);
  }

  handleLavorazione(): void {

    if (this.update) {
      let file = (this.files[0] ? this.files[0][0].name : '');
      let gar: number = (this.Garanzia ? 1 : 0);
      let lavorazione = new Lavorazione(this.IdAssistenza, this.PrezzoTotale.toString(), this.Descrizione, this.Note, file, gar, this.IdLavorazione);
      $('#cover-spin').show();
      this.service.updateLavorazione(lavorazione).subscribe({
        next: data => {

          let filesToUpload: File[] = this.files;
          const formData = new FormData();

          Array.from(filesToUpload).map((file, index) => {
            return formData.append('file' + index, file[0], file[0].name);
          });

          this.service.uploadProperty(formData).subscribe({
            next: data => {
              $('#cover-spin').hide();
              Swal.fire({
                icon: 'success',
                title: 'Inserimento completato!',
                showConfirmButton: false,
                timer: 1500
              }).then((result) => {
                this.modalService.dismissAll();
                this.loadLavorazioni();
              })

            },
            error: error => {
              $('#cover-spin').hide();
              this.Toast.fire({
                icon: 'error',
                title: 'Errore!'
              })
            }
          })

        },
        error: error => { }
      })
    } else {
      let file = (this.files[0] ? this.files[0][0].name : '');
      let gar: number = (this.Garanzia ? 1 : 0);
      let lavorazione = new Lavorazione(this.IdAssistenza, this.PrezzoTotale.toString(), this.Descrizione, this.Note, file, gar);
      $('#cover-spin').show();
      this.service.insertLavorazione(lavorazione).subscribe({
        next: data => {
          let id = data[0].ID;
          this.Interventi.forEach(element => {
            element.IdLavorazione = id;
          });

          let filesToUpload: File[] = this.files;
          const formData = new FormData();

          Array.from(filesToUpload).map((file, index) => {
            return formData.append('file' + index, file[0], file[0].name);
          });

          this.service.uploadProperty(formData).subscribe({
            next: data => {
              $('#cover-spin').hide();
              Swal.fire({
                icon: 'success',
                title: 'Inserimento completato!',
                showConfirmButton: false,
                timer: 1500
              }).then((result) => {
                this.modalService.dismissAll();
                this.loadLavorazioni();
              })

            },
            error: error => {
              $('#cover-spin').hide();
              this.Toast.fire({
                icon: 'error',
                title: 'Errore!'
              })
            }
          })

          this.service.insertIntervento(this.Interventi).subscribe();

        },
        error: error => { }
      })
    }


  }


  acceptDataOrologio(data) {
    this.IdOrologio = data;
  }
  acceptDataCliente(data) {
    this.IdCliente = data;
  }

  loadLavorazioni(): void {
    this.service.getLavorazioneByIdAssistenza({ IdAssistenza: this.IdAssistenza }).subscribe(data => this.Data = data);
  }

  loadInterventi(id: number): void {
    this.service.getInterventoByIdLavorazione({ IdLavorazione: id }).subscribe(data => {
      $(data).each((index, value) => {
        this.Interventi.push(new Intervento(value["Descrizione"], value["Prezzo"], value["Tipologia"], value["IdLavorazione"], value["IdIntervento"]));
      })
    });
  }

  deleteLavorazione(id): void {

    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo articolo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { idLavorazione: id };
        this.service.deleteLavorazione(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Elemento eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadLavorazioni();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  handleIntervento(): void {
    let intervento = new Intervento(this.desc, this.prezzo, this.tipologia, this.IdLavorazione);
    this.Interventi.push(intervento);
    this.PrezzoTotale += this.prezzo;
    this.desc = '';
    this.tipologia = '';
    this.prezzo = null;

    if (this.update) {
      let int: Intervento[] = [];
      int.push(intervento);
      this.service.insertIntervento(int).subscribe(data => { this.Toast.fire({ icon: 'success', title: 'Inserimento completato!' }); });
    }

  }

  deleteIntervento(i: number, id: number): void {
    this.PrezzoTotale -= this.Interventi[i].Prezzo;
    this.Interventi.splice(i, 1);
    if (this.update && id) {
      this.service.deleteIntervento({ IdIntervento: id }).subscribe(data => { this.Toast.fire({ icon: 'success', title: 'Eliminazione completata!' }); });
    }
  }

  formatDecimal(n: number) {
    return n.toFixed(2).replace(".", ",");
  }

  cutData(data: string): string {
    let d = new Date(data);
    let result = d.toLocaleDateString().split("/")[2] + "-" + this.fixMonth(d.toLocaleDateString().split("/")[1]) + "-" + this.fixMonth(d.toLocaleDateString().split("/")[0]);
    return result;
  }
  fixMonth(m: string) {
    if (m.length == 1) {
      m = '0' + m;
    }
    return m;
  }

  async stampaDoc(): Promise<void> {
    this.LavorazOpz = [];
    this.LavorazObb = [];
    this.totalprice = 0;
   this.service.getAssistenzaAllById({ IdAssistenza: this.IdAssistenza }).subscribe(async data => {

      let d = data[0];

      this.PrevNumb = d.NumRiparaz;
      this.Nome = d.Nome;
      this.Cognome = d.Cognome;
      this.Tel = d.Cellulare;
      this.Email = d.Email;
      
      this.Matricola = d.Matricola;
      this.DataRic = new Date(d.Data).toLocaleDateString();
      this.Referenza = d.Referenza;

      await this.service.getInterventoByIdAssistenza({ IdAssistenza: this.IdAssistenza }).subscribe(data => {
        $(data).each((index, value) => {
          if (value["Tipologia"] == 'Necessario') {
            this.LavorazObb.push(new Intervento(value["Descrizione"], value["Prezzo"], value["Tipologia"], value["IdLavorazione"], value["IdIntervento"]));
          } else {
            this.LavorazOpz.push(new Intervento(value["Descrizione"], value["Prezzo"], value["Tipologia"], value["IdLavorazione"], value["IdIntervento"]));
          }
          this.totalprice += value["Prezzo"];
          if(index == 0){
            this.Notes = value["Note"];
          }
        })

      })
    });

    


  }

  public stamp1():void{
    var printContents = document.getElementById("printAssistenza").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  }
  public stamp2():void{
    var printContents = document.getElementById("printGreen").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  }
  public stamp3():void{
    var printContents = document.getElementById("printGreen2").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  }

  async stampaVerde(): Promise<void> {
    await this.service.getOrologioByIdAssistenza({ IdAssistenza: this.IdAssistenza }).subscribe(data => {

      let d = data[0];

      this.PrintMatricola = d.Matricola;
      this.PrintModel = d.Referenza;
      this.PrintDate = new Date(d.Data).toLocaleDateString();


    });
  }
  async stampaEpson(): Promise<void> {
    await this.service.getOrologioByIdAssistenza({ IdAssistenza: this.IdAssistenza }).subscribe(data => {

      let d = data[0];

      this.PrintMatricola = d.Matricola;
      this.PrintModel = d.Referenza;
      this.PrintDate = new Date(d.Data).toLocaleDateString();
    });

  }

}
