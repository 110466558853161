import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insert-clienti',
  templateUrl: './insert-clienti.component.html',
  styleUrls: ['./insert-clienti.component.css']
})
export class InsertClientiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
