import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insert-orologi',
  templateUrl: './insert-orologi.component.html',
  styleUrls: ['./insert-orologi.component.css']
})
export class InsertOrologiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
