import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/api.service';

@Component({
  selector: 'app-volta-stampa-negozio-insert',
  templateUrl: './volta-stampa-negozio-insert.component.html',
  styleUrls: ['./volta-stampa-negozio-insert.component.css']
})
export class VoltaStampaNegozioInsertComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private service: ApiService, private modalService: NgbModal) { }
  Data: any;
  closeModal: any;

  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    this.loadLavorazioni();
  }

  NuovaStampa(): void {
    var codice = $("#codice").val();
    var numero = $("#numero").val();
    var attops = $("#attops").val();
    var dataprot = new Date($("#dataprot").val().toString() != '' ? $("#dataprot").val().toString() : new Date());
    dataprot.setHours(dataprot.getHours() + 1);
    var numerorif = $("#numerorif").val();
    var livello = $("#livello").val();
    var contatto = $("#contatto").val();
    var datarif = new Date($("#datarif").val().toString() != '' ? $("#datarif").val().toString() : new Date());
    datarif.setHours(datarif.getHours() + 1);
    var IntNominat = $("#IntNominat").val();
    var IntNominat = $("#IntNominat").val();
    var IntCodice = $("#IntCodice").val();
    var IntTel = $("#IntTel").val();
    var IntIndirizzo = $("#IntIndirizzo").val();
    var IntCellulare = $("#IntCellulare").val();
    var IntCAP = $("#IntCAP").val();
    var IntComune = $("#IntComune").val();
    var IntProvincia = $("#IntProvincia").val();
    var IntFAX = $("#IntFAX").val();
    var IntNazione = $("#IntNazione").val();
    var IntCodfis = $("#IntCodfis").val();
    var IntPiva = $("#IntPiva").val();
    var IntEmail = $("#IntEmail").val();
    var IntDataNa = new Date($("#IntDataNa").val().toString() != '' ? $("#IntDataNa").val().toString() : new Date());
    IntDataNa.setHours(IntDataNa.getHours() + 1);
    var IntLuogoNa = $("#IntLuogoNa").val();
    var IntNazioneNa = $("#IntNazioneNa").val();
    var IntSesso = $("#IntSesso").val();
    var IntTipoDoc = $("#IntTipoDoc").val();
    var IntNumero = $("#IntNumero").val();
    var IntDataEmiss = new Date($("#IntDataEmiss").val().toString() != '' ? $("#IntDataEmiss").val().toString() : new Date());
    IntDataEmiss.setHours(IntDataEmiss.getHours() + 1);
    var IntDataScad = new Date($("#IntDataScad").val().toString() != '' ? $("#IntDataScad").val().toString() : new Date());
    IntDataScad.setHours(IntDataEmiss.getHours() + 1);
    var Note = $("#Note").val();

    // var pass = true;
    // $(".req").each(function (index: any, value: any) {
    //   if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
    //     $(value).addClass("rederror");
    //     pass = false;
    //   } else {
    //     $(value).removeClass("rederror");
    //   }
    // });
    // if (pass != true) {
    //   this.Toast.fire({
    //     icon: 'error',
    //     title: 'Devi compilare tutti i campi!'
    //   })
    //   return;
    // }

    let data = {
      Codice: codice,
      NumeroProt: numero,
      AttoPs: attops,
      DataProt: dataprot.toISOString(),
      NumeroRif: numerorif,
      Livello: livello,
      Contatto: contatto,
      DataRif: datarif.toISOString(),
      IntestazioneNominativo: IntNominat,
      IntestazioneCodice: IntCodice,
      IntestazioneTelefono: IntTel,
      IntestazioneIndirizzo: IntIndirizzo,
      IntestazioneCellulare: IntCellulare,
      IntestazioneCAP: IntCAP,
      IntestazioneComune: IntComune,
      IntestazioneProvincia: IntProvincia,
      IntestazioneFax: IntFAX,
      IntestazionePiva: IntPiva,
      IntestazioneCodFis: IntCodfis,
      IntestazioneEmail: IntEmail,
      IntestazioneNazione: IntNazione,
      IntestazioneDataNa: IntDataNa.toISOString(),
      IntestazioneLuogoNa: IntLuogoNa,
      IntestazioneNazioneNa: IntNazioneNa,
      IntestazioneSesso: IntSesso,
      IntestazioneTipoDoc: IntTipoDoc,
      IntestazioneNumero: IntNumero,
      IntestazioneDataEmiss: IntDataEmiss.toISOString(),
      IntestazioneDataScad: IntDataScad.toISOString(),
      Note: Note
    }
    $('#cover-spin').show();
    this.service.insertStampaNegozio(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          window.location.href = "/#/stampanegozio";

        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  loadLavorazioni(): void {
    this.service.getLavorazioneCopy().subscribe({
      next: data => {
        this.Data = data;

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: error
        })
      }
    })
  }

  deleteLavorazione(id): void {

    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo articolo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { idLavorazione: id };
        this.service.deleteLavorazioneCopy(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Elemento eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadLavorazioni();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  NuovaLavorazione(): void {
    var ArtCodice = $("#ArtCodice").val();
    var ArtRiferimento = $("#ArtRiferimento").val();
    var ArtReferenza = $("#ArtReferenza").val();
    var ArtDescrizione = $("#ArtDescrizione").val();
    var ArtTipoArticolo = $("#ArtTipoArticolo").val();
    var ArtMarca = $("#ArtMarca").val();
    var ArtPrezzoPrev = $("#ArtPrezzoPrev").val();
    var ArtConsPrev = $("#ArtConsPrev").val();
    var ArtGaranzia = $("#ArtGaranzia").is(":checked") ? 1 : 0;
    var ArtAssicurazione = $("#ArtAssicurazione").is(":checked") ? 1 : 0;
    var ArtLavDaEff = $("#ArtLavDaEff").val();
    var ArtLavEff = $("#ArtLavEff").val();

    var ArtCostoEst = $("#ArtCostoEst").val();
    var ArtCostoInt = $("#ArtCostoInt").val();
    var ArtCostoTot = $("#ArtCostoTot").val();
    var ArtRicarico = $("#ArtRicarico").val();
    var ArtPrezzo = $("#ArtPrezzo").val();

    var ArtCosto = $("#ArtCosto").val();
    var ArtPrezzoFor = $("#ArtPrezzoFor").val();

    var ArtPrezzoTot = $("#ArtPrezzoTot").val();
    var ArtScontoTot = $("#ArtScontoTot").val();
    var ArtDapagareTot = $("#ArtDapagareTot").val();
    var ArtPagatoTot = $("#ArtPagatoTot").val();
    var ArtResiduoTot = $("#ArtResiduoTot").val();

    var ArtTipologia = $("#ArtTipologia").is(":checked") ? 1 : 0;
    var ArtMatricola = $("#ArtMatricola").val();

    var ArtStato = $("#ArtStato").val();


    let data = {
      Codice: ArtCodice,
      Descrizione: ArtDescrizione,
      Referenza: ArtReferenza,
      Riferimento: ArtRiferimento,
      Tipo: ArtTipoArticolo,
      Marca: ArtMarca,
      PrezzoPrev: ArtPrezzoPrev,
      ConsPrev: ArtConsPrev,
      Garanzia: ArtGaranzia,
      Assicurazione: ArtAssicurazione,
      LavorazioneDaEff: ArtLavDaEff,
      LavorazioneEffett: ArtLavEff,
      CostoEsterno: ArtCostoEst,
      CostoInterno: ArtCostoInt,
      CostoTotale: ArtCostoTot,
      Ricarico: ArtRicarico,
      Prezzo: ArtPrezzo,
      FornCosto: ArtCosto,
      FornPrezzo: ArtPrezzoFor,
      TotPrezzo: ArtPrezzoTot,
      TotSconto: ArtScontoTot,
      TotDaPagare: ArtDapagareTot,
      TotPagato: ArtPagatoTot,
      TotResiduo: ArtResiduoTot,
      Stato: ArtStato,
      Tipologia: ArtTipologia,
      Matricola: ArtMatricola
    }
    $('#cover-spin').show();
    this.service.insertLavorazioneCopy(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.loadLavorazioni();
          this.modalService.dismissAll();

        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

}
