import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/api.service';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { Ordine } from 'app/models/ordine';


@Component({
  selector: 'app-ordini-modifica',
  templateUrl: './ordini-modifica.component.html',
  styleUrls: ['./ordini-modifica.component.css']
})
export class OrdiniModificaComponent implements OnInit {

  constructor(private service: ApiService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.idordine = params['id'];
    });
  }

  files: File[] = [];
  public dataaquisto: string;
  public chiamatoil: string;
  public note: string;
  public stato: string;
  public acconto: string;
  public prezzo: string;
  public tipopag: string;
  public dateins: string;
  public idorologio: number;
  public idcliente: number;
  public idordine: number;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  ngOnInit(): void {
    this.getOrdineById();
  }

  onSelect(event: any) {
    this.files.push(event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);

  }
  acceptDataOrologio(data) {
    this.idorologio = data;
  }
  acceptDataCliente(data) {
    this.idcliente = data;
  }
  handleOrdine(f: NgForm): void {
    let file = (this.files[0] ? this.files[0][0].name : '');
    let ordine = new Ordine(this.dataaquisto, this.chiamatoil, this.prezzo,null, this.note, this.acconto, this.tipopag, this.stato, file, this.idorologio,0,0,0, this.idcliente, this.idordine);
    $('#cover-spin').show();
    this.service.updateOrdine(ordine).subscribe({
      next: data => {

        let filesToUpload: File[] = this.files;
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
          return formData.append('file' + index, file[0], file[0].name);
        });

        this.service.uploadProperty(formData).subscribe({
          next: data => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'success',
              title: 'Modifica effettuata!'
            })

          },
          error: error => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Errore!'
            })
          }
        })



      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

  getOrdineById(): void {
    this.service.getOrdineById({ IdOrdine: this.idordine }).subscribe(data => {
      let d = data[0];

      this.dataaquisto = d.DataAcquisto;
      this.chiamatoil = d.ChiamatoIl;
      this.note = d.Note;
      this.stato = d.Stato;
      this.acconto = d.Acconto;
      this.prezzo = d.PrezzoFinale;
      this.tipopag = d.TipoPagamento;
      this.idcliente = d.IdCliente;
      this.idorologio = d.IdOrologio;
      this.dateins = new Date(d.DataInserimento).toLocaleDateString();
    })
  }

  formatDecimal(n: number) {
    return n.toFixed(2).replace(".", ",");
  }

  formatData(data): string {
    let d = new Date(data);
    return d.toLocaleDateString();
  }

}
