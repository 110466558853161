import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5';
import Swal from 'sweetalert2'

declare var $: any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  userdata: any;
  closeModal: any;
  constructor(private modalService: NgbModal,private cookieService: CookieService,private service: ApiService, private cookie: CookieService) { }
  
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  ngOnInit(): void {
    $("#preloader").hide();
    //$('.carousel').carousel();
    $('#cover-spin').hide();
    $('.wrap-input100').removeClass("alert-validate");

    
  }

  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  Login():void {

    $('.wrap-input100').removeClass("alert-validate");
    var usr = $("#usr").val();
    var psw = $("#psw").val();
    var flag = $("#ckb1").is(":checked") ? 1 : 0;
    if (usr && psw) {
      $('#cover-spin').show();
      let hash = Md5.hashStr(psw.toString());
      let data = {
        Username: usr,
        Password: hash
      };
      this.service.signIn(data).subscribe({
        next: data => {
          this.userdata = data;
          if (this.userdata[0]) {
            if (flag == 0) {
              this.cookie.set('session', this.userdata[0].Hash)
            } else {
              this.cookie.set('session', this.userdata[0].Hash, 14)
            }
            this.Toast.fire({
              icon: 'success',
              // title: 'Signed in successfully',
              title: 'Bentornato ' + this.userdata[0].Name + "!"
            })
            this.modalService.dismissAll();
            window.location.href = "/#/ordini";
          } else {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Dati incorretti!'
            })
          }

        },
        error: error => {
          this.userdata = error.message;
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Errore!'
          })
        }
      })
    } else {
      if (!psw)
        $('#divPsw').addClass("alert-validate");
      if (!usr)
        $('#divUsr').addClass("alert-validate");
      this.Toast.fire({
        icon: 'error',
        title: 'Compila tutti i campi!'
      })

    }
  }
}
