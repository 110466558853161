import { Component, OnInit, EventEmitter,Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ApiService } from 'app/api.service';
import { Cliente } from 'app/models/cliente';
import { data } from 'jquery';
import { Observable, Subject } from 'rxjs';

import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  @Output() IdCliente = new EventEmitter<number>();
  @Input() ID: number;

  Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2500, timerProgressBar: true })
  cliente$;
  public added: boolean = false;
  private searchTerms = new Subject<string>();
  public isAssistenza:boolean = false;

  public nome: string;
  public cognome: string;
  public telefono: string;
  public cellulare: string;
  public email: string;
  public regione: number;
  public provincia: number;
  public comune: number;
  public cap: string;
  public indirizzo: string;
  public date: string;
  public id: number;
  public followup: boolean;
  public followupRolex: boolean;
  public followupTudor: boolean;
  public Rating: number = 1;
  public LinkRolex: string;
  public LinkTudor: string;

  public Region: any;
  constructor(private _service: ApiService) { }
  public Data;
  search(term: string): void {
    this.searchTerms.next(term);

  }
  public handleCliente(f: NgForm): void {
    this.handleStars();
    if (!this.added) {
      let fu: number = (this.followup ? 1 : 0);
      let fuR: number = (this.followupRolex ? 1 : 0);
      let fuT: number = (this.followupTudor ? 1 : 0);
      let cliente = new Cliente(this.nome, this.cognome, this.telefono, this.cellulare, this.email, this.regione, this.provincia, this.comune, this.cap, this.indirizzo, fu, this.Rating, 0, fuR, fuT, this.LinkRolex, this.LinkTudor);
      this._service.insertCliente(cliente).subscribe({
        next: data => { this.Toast.fire({ icon: 'success', title: 'Inserimento completato!' }); this.id = data[0].ID; this.IdCliente.emit(this.id); },
        error: error => {  }
      })
      this.added = true;
    } else {
      let fu: number = (this.followup ? 1 : 0);
      let fuR: number = (this.followupRolex ? 1 : 0);
      let fuT: number = (this.followupTudor ? 1 : 0);
      let cliente = new Cliente(this.nome, this.cognome, this.telefono, this.cellulare, this.email, this.regione, this.provincia, this.comune, this.cap, this.indirizzo, fu, this.Rating, this.id, fuR, fuT, this.LinkRolex, this.LinkTudor);
      this._service.updateCliente(cliente).subscribe({
        next: data => { this.Toast.fire({ icon: 'success', title: 'Aggiornamento completato!' }) },
        error: error => {  }
      })
    }
  }

  region() {
    this._service.getRegion().subscribe(data => this.Region = data)
  }

  province() {
    $("#city").html("<option>-- Nessuna Scelta --</option>")
    $("#city").attr('disabled', 'disabled');
    $("#province").removeAttr('disabled');
    let val = this.regione.toString();
    if (val != "-- Nessuna Scelta --") {
      let data = { IdRegion: val }
      this._service.getProvince(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["IdProvince"] + "'>" + value["Name"] + "</option>";
          });
          $("#province").html(cont);
          if (this.Data) {
            // $("#province").val(this.Data[0].Provincia);
            this.provincia = this.Data[0].Provincia;
            this.city();
          }
        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    } else {
      $("#province").html("<option>-- Nessuna Scelta --</option>")
      $("#province").attr('disabled', 'disabled');
      $("#city").html("<option>-- Nessuna Scelta --</option>")
      $("#city").attr('disabled', 'disabled');

    }

  }

  city() {
    $("#city").removeAttr('disabled');
    let val = this.provincia.toString();
    if (val != "-- Nessuna Scelta --") {
      let data = { IdProvince: val }
      this._service.getCity(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["City"] + "'>" + value["Name"] + "</option>";
          });
          $("#city").html(cont);
          if (this.Data) {
            // $("#city").val(this.Data[0].Comune);
            this.comune = this.Data[0].Comune;
          }
        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    }

  }

  getCliente(id: number): void {
    this._service.getClienteById({ IdCliente: id }).subscribe(data => {
      this.Data = data;
      let d = this.Data[0];
      this.added = true;
      this.nome = d.Nome;
      this.cognome = d.Cognome;
      this.telefono = d.Telefono;
      this.cellulare = d.Cellulare;
      this.email = d.Email;
      this.cap = d.CAP;
      this.indirizzo = d.Indirizzo;
      this.date = new Date(d.Data).toLocaleDateString();
      this.id = d.IdCliente;
      this.IdCliente.emit(this.id);
      this.regione = d.Regione;
      $('#star'+ d.Rating).prop('checked', true);
      this.followup = (d.Followup == 1 ? true : false);
      this.followupRolex = (d.FollowupRolex == 1 ? true : false);
      this.followupTudor = (d.FollowupTudor == 1 ? true : false);
      this.LinkRolex = d.LinkRolex;
      this.LinkTudor = d.LinkTudor;
      this.province();
    })

  }

  handleStars():void{
    this.Rating = ($("input:radio[name=rate]:checked").val() ? +$("input:radio[name=rate]:checked").val() : 1);
  }

  ngOnInit(): void {
    this.region();
    this.cliente$ = this.searchTerms.pipe(
      distinctUntilChanged(),
      switchMap((term: string) => this._service.getClienteStartingWith({ Nome: term })),
    );
    $('#star1').prop('checked', true);
    setInterval(() => { 
      this.handleStars();
     }, 1000);

    if(this.ID){
      this.getCliente(this.ID)
    }

    let url:string = window.location.href;
    if(url.split("/")[4] == 'assistenze'){
      this.isAssistenza = true;
    }
  }

  stapaPrivacy(): void
  {
    var printContents = document.getElementById("printPrivacy").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    // window.location.reload();
  }

}
