import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';
import itLocale from '@fullcalendar/core/locales/it';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2'
import { ApiService } from 'app/api.service';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'Select2';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  constructor(private service: ApiService, private modalService: NgbModal) { }
  closeModal: any;
  dataClick = '';
  lastUsers;
  modalType;
  public exampleData: Array<Select2OptionData>;
  public _UsersValue: string[];
  public data: any;
  get UsersValue(): string[] {
    return this._UsersValue;
  }
  set UsersValue(value: string[]) {
    this._UsersValue = value;
  }

  public options: Options;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  Events = [];
  calendarOptions: CalendarOptions = {
    locales: [itLocale],
    locale: 'it',
    initialView: 'timeGridWeek',
    customButtons: {
      myCustomButton: {
        text: 'Aggiungi',
        click: function () {
          alert('clicked the custom button!');
        }
      }
    },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridDay,timeGridWeek,dayGridMonth'
    },
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    dateClick: this.handleDateClick.bind(this), // bind is important!
    events: [
      // { title: 'event 1\nMario', date: '2021-09-11 10:00:00', id: '1', groupId: "asdasdd", },
      // { title: 'event dffd', date: '2021-09-14', id: '3', display: 'background' },
      // { title: 'event 2', date: '2021-09-12', id: '4' }
    ],
    eventClick: this.editEvent.bind(this)
    ,
    editable: false,
    selectable: true,
    selectMirror: true,

  };


  

  ngOnInit(): void {
    this.loadData();
    this.service.getUser().subscribe({
      next: data => {
        var da = '[';
        $(data).each(function (index: any, value: any) {
          da += '{ "id": ' + value["IdUser"] + ', "text": "' + value["Name"] + ' ' + value["Surname"] + '" },';
        });
        let utenti = da.substring(0, da.length - 1) + "]";
        this.exampleData = JSON.parse(utenti);
      },
      error: error => {
        $('#cover-spin').hide();

      }
    })



    this.options = {
      multiple: true,
      closeOnSelect: false,
      width: '438'
    };

    setTimeout(() => {
      $(".fc-view-harness").css("background-color", "white");
    }, 100);


    

  }
  editEvent(arg: any) {
    this.GetAppointment(arg.event._def.publicId);

  }

  handleDateClick(arg: any) {
    let date = new Date(arg.dateStr);
    date.setHours(date.getHours() + 2);
    this.dataClick = date.toISOString().split(":00.")[0];
    $("#addbtn").click();
  }

  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
    if (this.dataClick) {
      $(".dat").val(this.dataClick);
      this.dataClick = '';
    }

    if (this.modalType) {
      $(".add").addClass("hidden");
      $(".edit").removeClass("hidden");
      $(".delete").removeClass("hidden");
      $("#titledit").removeClass("hidden");
      $("#titleadd").addClass("hidden");

      $("#title").val(this.modalType[0]["Title"]);
      $("#desc").val(this.modalType[0]["Description"]);
      $("#date").val(this.modalType[0]["Date"]);
      $("#id").val(this.modalType[0]["IdAppointment"])
      let tot = [];
      $(this.modalType).each(function (index: any, value: any) {
        tot.push(value['IdUser'].toString());
      });
      this._UsersValue = tot;
      this.lastUsers = tot;
      this.modalType = null;

    }else{
      this._UsersValue = [];
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  GetAppointment(id: any) {
    let data = { IdAppointment: id };

    this.service.getAppointmentById(data).subscribe({
      next: data => {

        this.modalType = data;
        $("#addbtn").click();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

  NewAppointment() {
    var title = $("#title").val();
    var desc = $("#desc").val();
    var date = new Date($("#date").val().toString());
    date.setHours(date.getHours() + 2);
    var pass = true;

    $(".reqU").each(function (index: any, value: any) {
      if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
        $(value).addClass("rederror");
        pass = false;
      } else {
        $(value).removeClass("rederror");
      }
    });

    if (pass != true) {
      this.Toast.fire({
        icon: 'error',
        title: 'Devi compilare tutti i campi!'
      })
      return;
    }


    let users = '';
    $(this._UsersValue).each(function (index: any, value: any) {
      users += value + ',';
    });

    let data = {
      Title: title,
      Desc: desc,
      Date: date.toISOString(),
      Users: users
    }


    $('#cover-spin').show();
    this.service.insertAppointment(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 2000
        }).then((result) => {
          this.loadData();
          this.modalService.dismissAll();
        })
      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }


  UpdateAppointment() {
    var title = $("#title").val();
    var desc = $("#desc").val();
    var date = new Date($("#date").val().toString());
    date.setHours(date.getHours() );
    var pass = true;

    $(".reqU").each(function (index: any, value: any) {
      if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
        $(value).addClass("rederror");
        pass = false;
      } else {
        $(value).removeClass("rederror");
      }
    });

    if (pass != true) {
      this.Toast.fire({
        icon: 'error',
        title: 'Devi compilare tutti i campi!'
      })
      return;
    }


    let users = '';
    let vett = [];
    let vett1 = [];
    if (this.lastUsers.length <= this.UsersValue.length) {
      vett = this.lastUsers;
      vett1 = this.UsersValue;
      $(vett1).each(function (index: any, value: any) {
        if (!vett.includes(value)) {
          users += value + ',';
        }
      });

      // $(vett1).each(function (index: any, value: any) {
      //   users += value + ',';

      // });
    } else {
      vett = this.UsersValue;
      vett1 = this.lastUsers;
      $(vett1).each(function (index: any, value: any) {
        if (!vett.includes(value)) {
          users += value + ',';
        }
      });

      // $(vett1).each(function (index: any, value: any) {
      //   users += value + ',';

      // });
    }


    let data = {
      Title: title,
      Desc: desc,
      Date: date.toISOString(),
      Users: users,
      IdAppointment: $("#id").val()
    }


    $('#cover-spin').show();
    this.service.updateAppointment(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Modifica completata!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.loadData();
          this.modalService.dismissAll();
        })


      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }


  deleteAppointment(){
    Swal.fire({
      title: 'Sei sicuro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.delete($(e.currentTarget).attr("data-id"));
        $.ajax({
          url: '/api/appointment/delete/',
          type: 'POST',  // http method
          data: JSON.stringify({ IdAppointment : $("#id").val() }),  // data to submit
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          success: function () {
            Swal.fire({
              icon: 'success',
              title: 'Eliminazione completata!',
              showConfirmButton: false,
              timer: 1200
            }).then((result) => {
              window.location.reload();

            })
          },
          error: function (req: any, status: any) {
          }
        });
      }
    })

  }

  loadData() {

    function setColor(id){
      if(id == 1){
        return "#006AFF";
      }else if(id == 3){
        return "#FF78C2";
      }else{
        return "#FFAE00";
      }
    }

    this.service.getAppointments().subscribe({
      next: data => {
        this.data = data;

        var da = '[';
        let lastid = 0;
        $(data).each(function (index: any, value: any) {
          if(lastid != parseInt(value["IdAppointment"])){
            lastid = parseInt(value["IdAppointment"]);
            da += '{ "id": ' + value["IdAppointment"] + ', "title": "' + value["Title"] + '", "date": "' + value["Date"] + '" , "color": "'+ value["Color"] +'"},';
          }
          
        });
        let eventi = da.substring(0, da.length - 1) + "]";
        
        this.calendarOptions.events = JSON.parse(eventi);
      },
      error: error => {
        $('#cover-spin').hide();

      }
    })

  }
}
