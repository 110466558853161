import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'app/api.service';
import { Ordine } from 'app/models/ordine';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ordini-aggiungi',
  templateUrl: './ordini-aggiungi.component.html',
  styleUrls: ['./ordini-aggiungi.component.css']
})
export class OrdiniAggiungiComponent {

  constructor(private service: ApiService) { }
 
  files: File[] = [];
  public dataaquisto: string;
  public chiamatoil: string;
  public datainserimento: string;
  public note: string;
  public stato: string;
  public acconto: string;
  public prezzo: string;
  public tipopag: string;
  public idorologio: number;
  public idcliente: number;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  onSelect(event: any) {
    this.files.push(event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);

  }
  acceptDataOrologio(data) {
    this.idorologio = data;
  }
  acceptDataCliente(data) {
    this.idcliente = data;
  }
  handleOrdine(f: NgForm): void {
    let file = (this.files[0] ? this.files[0][0].name : '');
    let ordine = new Ordine(this.dataaquisto, this.chiamatoil, this.prezzo,this.datainserimento, this.note, this.acconto, this.tipopag, this.stato, file, this.idorologio,0,0,0, this.idcliente);
    $('#cover-spin').show();
    this.service.insertOrdine(ordine).subscribe({
      next: data => {
        
        let filesToUpload: File[] = this.files;
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
          return formData.append('file' + index, file[0], file[0].name);
        });

        this.service.uploadProperty(formData).subscribe({
          next: data => {
            $('#cover-spin').hide();
            Swal.fire({
              icon: 'success',
              title: 'Inserimento completato!',
              showConfirmButton: false,
              timer: 1500
            }).then((result) => {
              window.location.href = "/#/ordini";
    
            })

          },
          error: error => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Errore!'
            })
          }
        })

        

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }


}

