import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-volta-stampa-negozio-details',
  templateUrl: './volta-stampa-negozio-details.component.html',
  styleUrls: ['./volta-stampa-negozio-details.component.css']
})
export class VoltaStampaNegozioDetailsComponent implements OnInit {
  Data: any;
  Lavora: any;
  closeModal: any;
  Articoli: any;
  id: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  constructor(private service: ApiService, private route: ActivatedRoute, private modalService: NgbModal) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    let a = window.location.href;
  }

  triggerModal(content: any, type: any, idl?: any) {



    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });

    if (type == 'insert') {
      $("#modaltitle").html('<i class="fa fa-plus"></i> Aggiungi nuova Lavorazione');
      $("#modbtn").addClass('hidden');
      $("#adbtn").removeClass('hidden');
      this.Lavora = '';
    } else {
      $("#modaltitle").html('<i class="fa fa-wrench"></i> Aggiorna Lavorazione');
      $("#modbtn").removeClass('hidden');
      $("#adbtn").addClass('hidden');

      let data = { IdLavorazione: idl };

      this.service.getLavorazioneById(data).subscribe({
        next: data => {
          this.Lavora = data[0];
          if (this.Lavora["Tipologia"] == 1) { $("#ArtTipologia").prop('checked', true); }
          if (this.Lavora["Garanzia"] == 1) { $("#ArtGaranzia").prop('checked', true); }
          if (this.Lavora["Assicurazione"] == 1) { $("#ArtAssicurazione").prop('checked', true); }
        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.loadData();
    this.loadLavorazioni();
  }

  loadData(): void {
    let data = { IdStampa: this.id };

    this.service.getStampaByIdNegozio(data).subscribe({
      next: data => {
        this.Data = data[0];
      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }

  loadLavorazioni(): void {
    let data = { IdStampa: this.id };
    this.service.getLavorazioneByIdStampa(data).subscribe({
      next: data => {
        this.Articoli = data;

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: error
        })
      }
    })
  }

  AggiornaStampa(): void {
    var codice = $("#codice").val();
    var numero = $("#numero").val();
    var attops = $("#attops").val();
    var dataprot = new Date($("#dataprot").val().toString() != '' ? $("#dataprot").val().toString() : new Date());
    dataprot.setHours(dataprot.getHours() + 1);
    var numerorif = $("#numerorif").val();
    var livello = $("#livello").val();
    var contatto = $("#contatto").val();
    var datarif = new Date($("#datarif").val().toString() != '' ? $("#datarif").val().toString() : new Date());
    datarif.setHours(datarif.getHours() + 1);
    var IntNominat = $("#IntNominat").val();
    var IntNominat = $("#IntNominat").val();
    var IntCodice = $("#IntCodice").val();
    var IntTel = $("#IntTel").val();
    var IntIndirizzo = $("#IntIndirizzo").val();
    var IntCellulare = $("#IntCellulare").val();
    var IntCAP = $("#IntCAP").val();
    var IntComune = $("#IntComune").val();
    var IntProvincia = $("#IntProvincia").val();
    var IntFAX = $("#IntFAX").val();
    var IntNazione = $("#IntNazione").val();
    var IntCodfis = $("#IntCodfis").val();
    var IntPiva = $("#IntPiva").val();
    var IntEmail = $("#IntEmail").val();
    var IntDataNa = new Date($("#IntDataNa").val().toString() != '' ? $("#IntDataNa").val().toString() : new Date());
    IntDataNa.setHours(IntDataNa.getHours() + 1);
    var IntLuogoNa = $("#IntLuogoNa").val();
    var IntNazioneNa = $("#IntNazioneNa").val();
    var IntSesso = $("#IntSesso").val();
    var IntTipoDoc = $("#IntTipoDoc").val();
    var IntNumero = $("#IntNumero").val();
    var IntDataEmiss = new Date($("#IntDataEmiss").val().toString() != '' ? $("#IntDataEmiss").val().toString() : new Date());
    IntDataEmiss.setHours(IntDataEmiss.getHours() + 1);
    var IntDataScad = new Date($("#IntDataScad").val().toString() != '' ? $("#IntDataScad").val().toString() : new Date());
    IntDataScad.setHours(IntDataEmiss.getHours() + 1);
    var Note = $("#Note").val();

    // var pass = true;
    // $(".req").each(function (index: any, value: any) {
    //   if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
    //     $(value).addClass("rederror");
    //     pass = false;
    //   } else {
    //     $(value).removeClass("rederror");
    //   }
    // });
    // if (pass != true) {
    //   this.Toast.fire({
    //     icon: 'error',
    //     title: 'Devi compilare tutti i campi!'
    //   })
    //   return;
    // }

    let data = {
      Codice: codice,
      NumeroProt: numero,
      AttoPs: attops,
      DataProt: dataprot.toISOString(),
      NumeroRif: numerorif,
      Livello: livello,
      Contatto: contatto,
      DataRif: datarif.toISOString(),
      IntestazioneNominativo: IntNominat,
      IntestazioneCodice: IntCodice,
      IntestazioneTelefono: IntTel,
      IntestazioneIndirizzo: IntIndirizzo,
      IntestazioneCellulare: IntCellulare,
      IntestazioneCAP: IntCAP,
      IntestazioneComune: IntComune,
      IntestazioneProvincia: IntProvincia,
      IntestazioneFax: IntFAX,
      IntestazionePiva: IntPiva,
      IntestazioneCodFis: IntCodfis,
      IntestazioneEmail: IntEmail,
      IntestazioneNazione: IntNazione,
      IntestazioneDataNa: IntDataNa.toISOString(),
      IntestazioneLuogoNa: IntLuogoNa,
      IntestazioneNazioneNa: IntNazioneNa,
      IntestazioneSesso: IntSesso,
      IntestazioneTipoDoc: IntTipoDoc,
      IntestazioneNumero: IntNumero,
      IntestazioneDataEmiss: IntDataEmiss.toISOString(),
      IntestazioneDataScad: IntDataScad.toISOString(),
      Note: Note,
      IdStampa: this.id
    }
    $('#cover-spin').show();
    this.service.updateStampaNegozio(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'success',
          title: 'Modidica effettuata con successo!'
        })


      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  deleteLavorazione(id): void {

    Swal.fire({
      title: 'Sei sicuro di voler eliminare definitivamente questo articolo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { idLavorazione: id };
        this.service.deleteLavorazione(data).subscribe({
          next: data => {
            Swal.fire({
              title: 'Elemento eliminato con successo!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              this.loadLavorazioni();

            })
          },
          error: error => {
            $('#cover-spin').hide();
            // this.Toast.fire({
            //   icon: 'error',
            //   title: 'Error!'
            // })
          }
        })
      }
    })
  }

  //cutData(data: string): string {
  //  let d = new Date(data);
  //  return d.toISOString().split("T")[0];
  //}
  //cutDatetime(data: string): string {
  //  let d = new Date(data);
  //  return d.toISOString().replace("T", " ");
  //}
  cutData(data: string): string {
    let d = new Date(data);
    let result = d.toLocaleDateString().split("/")[2] + "-" + this.fixMonth(d.toLocaleDateString().split("/")[1]) + "-" + this.fixMonth(d.toLocaleDateString().split("/")[0]);
    return result;
  }
  fixMonth(m: string) {
    if (m.length == 1) {
      m = '0' + m;
    }
    return m;
  }

  //stampaDoc(id): void {
  //  let data = { IdStampa: id };

  //  this.service.getStampaById(data).subscribe({
  //    next: data => {

  //      var ris = data[0];
  //      let d = ris["DestinazioneIndirizzo"] + '<br>' + ris["DestinazioneCAP"] + ' ' + ris["DestinazioneComune"] + ' ' + ris["DestinazioneProvincia"] + ', ' + ris["DestinazioneNazione"];
  //      $("#html-destinazione").html(d);
  //      d = ris["IntestazioneNominativo"] + '<br>' + ris["IntestazioneIndirizzo"] + '<br>' + ris["IntestazioneCAP"] + ' ' + ris["IntestazioneComune"] + ' ' + ris["IntestazioneProvincia"] + ', ' + ris["IntestazioneNazione"];
  //      $("#html-intestazione").html(d);
  //      $("#html-documento").html(ris["Codice"]);
  //      $("#html-numero").html(ris["Numero"]);
  //      let dat = new Date(ris["Data"])
  //      $("#html-data").html(dat.toLocaleDateString());

  //      if (ris["Tn"]) {
  //        $("#html-tn-box").removeClass("hidden");
  //        $("#html-tn").html(ris["Tn"]);
  //      }
  //      if (ris["ValoreAssicurato"]) {
  //        $("#html-val-box").removeClass("hidden");
  //        $("#html-valoreass").html(ris["ValoreAssicurato"]);
  //      }
  //      $("#html-causalet").html(ris["TrasportoCausale"]);
  //      $("#html-mezzot").html(ris["TrasportoMezzo"]);
  //      $("#html-aspettot").html(ris["TrasportoAspetto"]);
  //      $("#html-collit").html(ris["TrasportoColli"]);
  //      $("#html-pesot").html(ris["TrasportoPeso"]);
  //      let da = new Date(ris["TrasportoData"]);
  //      $("#html-datat").html(da.toLocaleDateString() + " " + da.toLocaleTimeString());
  //      $("#html-resat").html(ris["TrasportoResa"]);
  //      $("#html-vettoret").html(ris["TrasportoVettore"]);

  //      let codice = '';
  //      let ref = '';
  //      let desc = '';
  //      let qta = '';
  //      let tot = 0;
  //      $.each(data, function (index: any, value: any) {
  //        tot += parseInt(value["Qta"]);
  //        codice += '<p class="TableParagraph" style="margin-top: 6.15pt; margin-left: 2.2pt; "> <span>' + value["CodiceArticolo"] + '</span></p>';
  //        qta += '<p class="TableParagraph" style="margin-top: 6.15pt; margin-left: 25.6pt;"> <span>' + value["Qta"] + '</span><span style="letter-spacing: 0.35pt"> </span><span>Pz.</span></p>';
  //        ref += '<p class="TableParagraph" style="margin-left: 2.25pt; margin-top: 6.15pt;">' + value["ReferenzaArticolo"] + '</p>';
  //        desc += '<span style="letter-spacing: 0.45pt">' + value["DescrizioneArticolo"] + '</span><br>';
  //      });
  //      $("#html-art-codice").html(codice);
  //      $("#html-art-desc").html(desc);
  //      $("#html-art-ref").html(ref);
  //      $("#html-art-qta").html(qta);
  //      $("#html-art-tot").html(tot.toString());
  //      // stampa
  //      var printContents = document.getElementById("printPrevAssistenza").innerHTML;
  //      document.body.innerHTML = printContents;
  //      window.print();
  //      window.location.reload();

  //    },
  //    error: error => {
  //      $('#cover-spin').hide();
  //      this.Toast.fire({
  //        icon: 'error',
  //        title: 'Error!'
  //      })
  //    }
  //  })
  //}

















  stampaDoc(id): void {
    let data = { IdStampa: id };

    this.service.getStampaByIdNegozio(data).subscribe({
      next: data => {

        var ris = data[0];
        $("#Anagrafica").html(ris["IntestazioneNominativo"] + ", " + ris["IntestazioneCodFis"])
        $("#Indirizzo").html(ris["IntestazioneComune"] + " (" + ris["IntestazioneProvincia"] + "), " + ris["IntestazioneIndirizzo"] + ", " + ris["IntestazioneCAP"])
        $("#Contatti").html(ris["IntestazioneCellulare"] + " - " + ris["IntestazioneEmail"])
        $("#IdRiparazione").html(ris["IdStampa"]);
        $("#Referenza").html(ris["Referenza"]);
        $("#Matricola").html(ris["Matricola"]);
        $("#Notee").html('Note: ' + ris["Note"]);
        let tot = '';
        let tot1 = '';
        $.each(data, function (index: any, value: any) {
          if (value["Tipologia"] == 1) {
            tot += '<div class="col-md-7"> <p>' + value["LavorazioneDaEff"] + '</p> </div> <div class="col-md-2"> <p>€' + value["Prezzo"] + '</p> </div> <div class="col-md-3"> <div class="row"> <p>Accetto: <i class="far fa-square"></i></p> &nbsp; &nbsp; <p>Rifiuto: <i class="far fa-square"></i></p> </div> </div>';
          } else {
            tot1 += '<div class="col-md-7"> <p>' + value["LavorazioneDaEff"] + '</p> </div> <div class="col-md-2"> <p>€' + value["Prezzo"] + '</p> </div> <div class="col-md-3"> <div class="row"> <p>Accetto: <i class="far fa-square"></i></p> &nbsp; &nbsp; <p>Rifiuto: <i class="far fa-square"></i></p> </div> </div>';
          }
        });
        $("#lavoraz").html(tot);
        $("#lavorazop").html(tot1);
        if (tot1 == '') {
          $("#optional").addClass("hidden");
        }


        // stampa
        var printContents = document.getElementById("printPrevAssistenza").innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }

  NuovaLavorazione(): void {
    var ArtCodice = $("#ArtCodice").val();
    var ArtRiferimento = $("#ArtRiferimento").val();
    var ArtReferenza = $("#ArtReferenza").val();
    var ArtDescrizione = $("#ArtDescrizione").val();
    var ArtTipoArticolo = $("#ArtTipoArticolo").val();
    var ArtMarca = $("#ArtMarca").val();
    var ArtPrezzoPrev = $("#ArtPrezzoPrev").val();
    var ArtConsPrev = $("#ArtConsPrev").val();
    var ArtGaranzia = $("#ArtGaranzia").is(":checked") ? 1 : 0;
    var ArtAssicurazione = $("#ArtAssicurazione").is(":checked") ? 1 : 0;
    var ArtLavDaEff = $("#ArtLavDaEff").val();
    var ArtLavEff = $("#ArtLavEff").val();

    var ArtCostoEst = $("#ArtCostoEst").val();
    var ArtCostoInt = $("#ArtCostoInt").val();
    var ArtCostoTot = $("#ArtCostoTot").val();
    var ArtRicarico = $("#ArtRicarico").val();
    var ArtPrezzo = $("#ArtPrezzo").val();

    var ArtCosto = $("#ArtCosto").val();
    var ArtPrezzoFor = $("#ArtPrezzoFor").val();

    var ArtPrezzoTot = $("#ArtPrezzoTot").val();
    var ArtScontoTot = $("#ArtScontoTot").val();
    var ArtDapagareTot = $("#ArtDapagareTot").val();
    var ArtPagatoTot = $("#ArtPagatoTot").val();
    var ArtResiduoTot = $("#ArtResiduoTot").val();

    var ArtStato = $("#ArtStato").val();
    var ArtTipologia = $("#ArtTipologia").is(":checked") ? 1 : 0;
    var ArtMatricola = $("#ArtMatricola").val();

    let data = {
      Codice: ArtCodice,
      Descrizione: ArtDescrizione,
      Referenza: ArtReferenza,
      Riferimento: ArtRiferimento,
      Tipo: ArtTipoArticolo,
      Marca: ArtMarca,
      PrezzoPrev: ArtPrezzoPrev,
      ConsPrev: ArtConsPrev,
      Garanzia: ArtGaranzia,
      Assicurazione: ArtAssicurazione,
      LavorazioneDaEff: ArtLavDaEff,
      LavorazioneEffett: ArtLavEff,
      CostoEsterno: ArtCostoEst,
      CostoInterno: ArtCostoInt,
      CostoTotale: ArtCostoTot,
      Ricarico: ArtRicarico,
      Prezzo: ArtPrezzo,
      FornCosto: ArtCosto,
      FornPrezzo: ArtPrezzoFor,
      TotPrezzo: ArtPrezzoTot,
      TotSconto: ArtScontoTot,
      TotDaPagare: ArtDapagareTot,
      TotPagato: ArtPagatoTot,
      TotResiduo: ArtResiduoTot,
      Stato: ArtStato,
      IdStampa: this.id,
      Tipologia: ArtTipologia,
      Matricola: ArtMatricola
    }
    $('#cover-spin').show();
    // this.service.insertLavorazione(data).subscribe({
    //   next: data => {
    //     $('#cover-spin').hide();
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Inserimento completato!',
    //       showConfirmButton: false,
    //       timer: 1500
    //     }).then((result) => {
    //       this.loadLavorazioni();
    //       this.modalService.dismissAll();

    //     })

    //   },
    //   error: error => {
    //     $('#cover-spin').hide();
    //     this.Toast.fire({
    //       icon: 'error',
    //       title: 'Errore!'
    //     })
    //   }
    // })
  }

  AggiornaLavorazione(id: any): void {
    var ArtCodice = $("#ArtCodice").val();
    var ArtRiferimento = $("#ArtRiferimento").val();
    var ArtReferenza = $("#ArtReferenza").val();
    var ArtDescrizione = $("#ArtDescrizione").val();
    var ArtTipoArticolo = $("#ArtTipoArticolo").val();
    var ArtMarca = $("#ArtMarca").val();
    var ArtPrezzoPrev = $("#ArtPrezzoPrev").val();
    var ArtConsPrev = $("#ArtConsPrev").val();
    var ArtGaranzia = $("#ArtGaranzia").is(":checked") ? 1 : 0;
    var ArtAssicurazione = $("#ArtAssicurazione").is(":checked") ? 1 : 0;
    var ArtLavDaEff = $("#ArtLavDaEff").val();
    var ArtLavEff = $("#ArtLavEff").val();

    var ArtCostoEst = $("#ArtCostoEst").val();
    var ArtCostoInt = $("#ArtCostoInt").val();
    var ArtCostoTot = $("#ArtCostoTot").val();
    var ArtRicarico = $("#ArtRicarico").val();
    var ArtPrezzo = $("#ArtPrezzo").val();

    var ArtCosto = $("#ArtCosto").val();
    var ArtPrezzoFor = $("#ArtPrezzoFor").val();

    var ArtPrezzoTot = $("#ArtPrezzoTot").val();
    var ArtScontoTot = $("#ArtScontoTot").val();
    var ArtDapagareTot = $("#ArtDapagareTot").val();
    var ArtPagatoTot = $("#ArtPagatoTot").val();
    var ArtResiduoTot = $("#ArtResiduoTot").val();

    var ArtStato = $("#ArtStato").val();
    var ArtTipologia = $("#ArtTipologia").is(":checked") ? 1 : 0;
    var ArtMatricola = $("#ArtMatricola").val();

    let data = {
      Codice: ArtCodice,
      Descrizione: ArtDescrizione,
      Referenza: ArtReferenza,
      Riferimento: ArtRiferimento,
      Tipo: ArtTipoArticolo,
      Marca: ArtMarca,
      PrezzoPrev: ArtPrezzoPrev,
      ConsPrev: ArtConsPrev,
      Garanzia: ArtGaranzia,
      Assicurazione: ArtAssicurazione,
      LavorazioneDaEff: ArtLavDaEff,
      LavorazioneEffett: ArtLavEff,
      CostoEsterno: ArtCostoEst,
      CostoInterno: ArtCostoInt,
      CostoTotale: ArtCostoTot,
      Ricarico: ArtRicarico,
      Prezzo: ArtPrezzo,
      FornCosto: ArtCosto,
      FornPrezzo: ArtPrezzoFor,
      TotPrezzo: ArtPrezzoTot,
      TotSconto: ArtScontoTot,
      TotDaPagare: ArtDapagareTot,
      TotPagato: ArtPagatoTot,
      TotResiduo: ArtResiduoTot,
      Stato: ArtStato,
      IdLavorazione: id,
      Tipologia: ArtTipologia,
      Matricola: ArtMatricola
    }
    $('#cover-spin').show();
    // this.service.updateLavorazione(data).subscribe({
    //   next: data => {
    //     $('#cover-spin').hide();
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Aggiornamento completato!',
    //       showConfirmButton: false,
    //       timer: 1200
    //     }).then((result) => {
    //       this.loadLavorazioni();
    //       this.modalService.dismissAll();

    //     })

    //   },
    //   error: error => {
    //     $('#cover-spin').hide();
    //     this.Toast.fire({
    //       icon: 'error',
    //       title: 'Errore!'
    //     })
    //   }
    // })
  }

}
