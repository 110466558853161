import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'app/api.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  cook: any;
  readonly HomePage = "/#/homepage";
  NotLoggetAddress = [
    window.location.host + "/#/auth/registration",
    window.location.host + this.HomePage
  ];
  constructor(private cookie: CookieService,private service: ApiService) { }

  title = 'Volta CRM';
  ngOnInit(): void {
    this.cook = this.cookie.get("session");
    let data = { IdUser: this.cook }
    this.service.getUserByHash(data).subscribe({
      next: data => {
        if (!data[0]) {
          if (!this.NotLoggetAddress.includes(window.location.href)) {
            window.location.href = this.HomePage;
          }
        }
        // else{
        //   if(data[0]["Role"] == "Amministratore"){
        //     $(".rolecontrol").css("visibility","visible");
        //   }else{
        //     $(".rolecontrol").css("visibility","hidden"); 
        //   }
        // }

      },
      error: error => {
        $('#cover-spin').hide();
      }
    });

    if (!this.cook) {
      if (!this.NotLoggetAddress.includes(window.location.href)) {
        window.location.href = this.HomePage;
      }
    }

    
  }

  

}
