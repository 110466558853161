import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from 'app/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-storico',
  templateUrl: './storico.component.html',
  styleUrls: ['./storico.component.css']
})
export class StoricoComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  Data: any;
  constructor(private service: ApiService) { }
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
      info: false,
    };
    this.loadData();
  }

  loadData(): void{
    $('#cover-spin').show();
    this.service.getStorico().subscribe({
      next: data => {
        this.Data = data;
        this.dtTrigger.next();
        $('#cover-spin').hide();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
              icon: 'error',
              title: error
            })
      }
    })
  }

  formatData(data): string{
    let d = new Date(data);
    return d.toLocaleDateString();
  }

  formatDecimal(n: number) {
    if(n){
      return '€ ' + n.toFixed(2).replace(".", ",");
    }else{
      return ''
    }
  }

}
