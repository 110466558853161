export class Orologio{
    constructor(
        public Marca: string = null,
        public Modello: string = null,
        public Referenza: string = null,
        public Matricola: string = null,
        public Descrizione: string = null,
        public Link: string = null,
        public IdOrologio: number = 0
    ){}
}