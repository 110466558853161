import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'app/api.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-ordini',
  templateUrl: './ordini.component.html',
  styleUrls: ['./ordini.component.css']
})
export class OrdiniComponent implements OnInit, OnDestroy {
 
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject < any > = new Subject<any>();
    Data: any;
    constructor(private service: ApiService) { }
    Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true
    })

    ngOnInit(): void {
      this.dtOptions = {
        dom: 'Bfrtip',
        info: false,
      };
      this.loadData();
    }

    loadData(): void {
      $('#cover-spin').show();
      this.service.getOrdine().subscribe({
        next: data => {
          this.Data = data;
          this.dtTrigger.next();
          $('#cover-spin').hide();

        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: error
          })
        }
      })
    }

    deleteOrdine(id): void {

      Swal.fire({
        title: 'Sei sicuro di voler eliminare definitivamente questo elemento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si!',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.service.deleteOrdine({ IdOrdine: id }).subscribe({
            next: data => {
              Swal.fire({
                title: 'Elemento eliminato con successo!',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              }).then((result) => {
                window.location.reload();
              })
            },
            error: error => {
              $('#cover-spin').hide();
              // this.Toast.fire({
              //   icon: 'error',
              //   title: 'Error!'
              // })
            }
          })
        }
      })
    }

    formatData(data): string{
      let d = new Date(data);
      return d.toLocaleDateString();
    }
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
    formatDecimal(n: number){
      return n.toFixed(2).replace(".",",");
    }
}
