export class Assistenza{
    constructor(
        public IdCliente: number = 0,
        public IdOrologio: number = 0,
        public NumRiparaz: string = null,
        public NumRiferimento: string = null,
        public Data: string = null,
        public Stato: string = null,
        public IdAssistenza: number = 0,
        public isClosed: boolean = false
    ){
        if(!this.Data){
            let date = new Date();
            this.Data = date.toISOString();
        }
    }
}