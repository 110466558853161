import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/api.service';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { Ordine } from 'app/models/ordine';

@Component({
  selector: 'app-update-preordine',
  templateUrl: './update-preordine.component.html',
  styleUrls: ['./update-preordine.component.css']
})
export class UpdatePreordineComponent implements OnInit {

  constructor(private service: ApiService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.idpreordine = params['id'];
    });
  }

  files: File[] = [];
  public dataaquisto: string;
  public chiamatoil: string;
  public note: string;
  public stato: string;
  public acconto: string;
  public prezzo: string;
  public tipopag: string;
  public idorologio: number;
  public dateins: string;
  public idorologio1: number = 0;
  public idorologio2: number = 0;
  public idorologio3: number = 0;

  public contId: number = 0;
  public id1 = false;
  public id2 = false;
  public id3 = false;

  public idcliente: number;
  public idpreordine: number;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  ngOnInit(): void {
    this.getOrdineById();
  }

  onSelect(event: any) {
    this.files.push(event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);

  }
  acceptDataOrologio(data) {
    this.idorologio = data;
  }

  acceptDataOrologio1(data) {
    this.idorologio1 = data;
  }
  acceptDataOrologio2(data) {
    this.idorologio2 = data;
  }
  acceptDataOrologio3(data) {
    this.idorologio3 = data;
  }

  onAddOrologio(): void {
    switch (this.contId) {
      case 0: {
        this.id1 = true;
        break;
      }
      case 1: {
        this.id2 = true;
        break;
      }
      case 2: {
        this.id3 = true;
        break;
      }
    }
    this.contId = this.contId + 1;
  }

  acceptDataCliente(data) {
    this.idcliente = data;
  }
  handlePreordine(f: NgForm): void {
    let file = (this.files[0] ? this.files[0][0].name : '');
    let preordine = new Ordine(this.dataaquisto, this.chiamatoil, this.prezzo,null, this.note, this.acconto, this.tipopag, this.stato, file, this.idorologio, this.idorologio1, this.idorologio2, this.idorologio3, this.idcliente,0, this.idpreordine);
    $('#cover-spin').show();
    this.service.updatePreordine(preordine).subscribe({
      next: data => {

        let filesToUpload: File[] = this.files;
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
          return formData.append('file' + index, file[0], file[0].name);
        });

        this.service.uploadProperty(formData).subscribe({
          next: data => {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'success',
              title: 'Modifica effettuata!'
            })

          },
          error: error => {
            console.log(error)
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Errore!'
            })
          }
        })



      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })
  }

  getOrdineById(): void {
    this.service.getPreordineById({ IdPreordine: this.idpreordine }).subscribe(data => {
      let d = data[0];

      this.dataaquisto = d.DataAcquisto;
      this.chiamatoil = d.ChiamatoIl;
      this.note = d.Note;
      this.stato = d.Stato;
      this.acconto = d.Acconto;
      this.prezzo = d.PrezzoFinale;
      this.tipopag = d.TipoPagamento;
      this.idcliente = d.IdCliente;
      this.idorologio = d.IdOrologio;
      this.dateins = new Date(d.DataInserimento).toLocaleDateString();
      this.idorologio1 = (d.IdOrologio1 ? d.IdOrologio1 : 0);
      if(this.idorologio1){
        this.contId = this.contId + 1;
        this.id1 =true;
      }
      this.idorologio2 = (d.IdOrologio2 ? d.IdOrologio2 : 0);
      if(this.idorologio2){
        this.contId = this.contId + 1;
        this.id2 =true;
      }
      
      this.idorologio3 = (d.IdOrologio3 ? d.IdOrologio3 : 0);
      if(this.idorologio3){
        this.contId = this.contId + 1;
        this.id3 =true;
      }
    })
  }

  formatDecimal(n: number) {
    return n.toFixed(2).replace(".", ",");
  }

  formatData(data): string {
    let d = new Date(data);
    return d.toLocaleDateString();
  }

}
