import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cliente } from './models/cliente';
import { Orologio } from './models/orologio';
import { Intervento } from './models/intervento';
import { Assistenza } from './models/assistenza';
import { Ordine } from './models/ordine';
import { Lavorazione } from './models/lavorazione';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly APIUrl = "/api";
  constructor(private http: HttpClient) { }

  signIn(val: any) {
    return this.http.post(this.APIUrl + "/user/signin/", val);
  }
  getUserByHash(val: any) {
    return this.http.post(this.APIUrl + "/user/getbyhash/", val);
  }
  getRegion() {
    return this.http.get(this.APIUrl + "/data/getregion/");
  }
  getProvince(val: any) {
    return this.http.post(this.APIUrl + "/data/getprovince/", val);
  }
  getCity(val: any) {
    return this.http.post(this.APIUrl + "/data/getcity/", val);
  }
  insertBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/insertbuyer/", val);
  }
  updateBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/updatebuyer/", val);
  }
  deleteBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/deletebuyer/", val);
  }
  getUser() {
    return this.http.get(this.APIUrl + "/user/");
  }
  updateUser(val: any) {
    return this.http.put(this.APIUrl + "/user/", val);
  }
  insertUser(val: any) {
    return this.http.post(this.APIUrl + "/user/insert/", val);
  }
  deleteUser(val: any) {
    return this.http.post(this.APIUrl + "/user/delete/", val);
  }
  changeUser(val: any) {
    return this.http.post(this.APIUrl + "/user/change/", val);
  }
  changePswUser(val: any) {
    return this.http.post(this.APIUrl + "/user/changepsw/", val);
  }
  getBuyer() {
    return this.http.get(this.APIUrl + "/data/getallbuyer");
  }
  getAllCities() {
    return this.http.get(this.APIUrl + "/data/getallcity/");
  }
  getFilterBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/selectfiltertbuyer/", val);
  }
  getBuyerById(val: any) {
    return this.http.post(this.APIUrl + "/data/getbuyerbyid/", val);
  }
  GetUserByUsername(val: any) {
    return this.http.post(this.APIUrl + "/user/getbyusername/", val);
  }


  insertSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/", val);
  }
  updateSeller(val: any) {
    return this.http.put(this.APIUrl + "/venditori/", val);
  }
  deleteSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/delete/", val);
  }
  getSeller() {
    return this.http.get(this.APIUrl + "/venditori/");
  }
  getFilterSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/selectfilterseller/", val);
  }
  getSellerById(val: any) {
    return this.http.post(this.APIUrl + "/venditori/getbyid/", val);
  }


  // insertOrdine(val: any) {
  //   return this.http.post(this.APIUrl + "/ordini/", val);
  // }
  // updateOrdine(val: any) {
  //   return this.http.post(this.APIUrl + "/ordini/update/", val);
  // }
  //updateOrdine(val: any) {
  //  return this.http.post(this.APIUrl + "/ordini/", val);
  //}
  // delateOrdine(val: any) {
  //   return this.http.post(this.APIUrl + "/ordini/delete/", val);
  // }
  // getOrdini() {
  //   return this.http.get(this.APIUrl + "/ordini/");
  // }
  //getFilterSeller(val: any) {
  //  return this.http.post(this.APIUrl + "/ordini/selectfilterseller/", val);
  //}
  // getOrdineByID(val: any) {
  //   return this.http.post(this.APIUrl + "/ordini/getbyid/", val);
  // }
  // uploadOrdini(val: any) {
  //   return this.http.post(this.APIUrl + "/ordini/upload", val, { reportProgress: true, observe: 'events' });
  // }


  insertRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/", val);
  }
  updateRequests(val: any) {
    return this.http.put(this.APIUrl + "/richiesta/", val);
  }
  deleteRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/delete/", val);
  }
  getRequests() {
    return this.http.get(this.APIUrl + "/richiesta/");
  }
  getFilterRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/selectfilterrequests/", val);
  }
  getRequestsById(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/getbyid/", val);
  }


  insertAppointment(val: any) {
    return this.http.post(this.APIUrl + "/appointment/", val);
  }
  updateAppointment(val: any) {
    return this.http.post(this.APIUrl + "/appointment/update/", val);
  }
  deleteAppointment(val: any) {
    return this.http.post(this.APIUrl + "/appointment/delete/", val);
  }
  getAppointments() {
    return this.http.get(this.APIUrl + "/appointment/");
  }
  getAppointmentById(val: any) {
    return this.http.post(this.APIUrl + "/appointment/getbyid/", val);
  }


  insertProposal(val: any) {
    return this.http.post(this.APIUrl + "/proposta/", val);
  }
  updateProposal(val: any) {
    return this.http.put(this.APIUrl + "/proposta/", val);
  }
  deleteProposal(val: any) {
    return this.http.post(this.APIUrl + "/proposta/delete/", val);
  }
  getProposals() {
    return this.http.get(this.APIUrl + "/proposta/");
  }
  getFilterProposals(val: any) {
    return this.http.post(this.APIUrl + "/proposta/selectfilterproposals/", val);
  }
  getProposalById(val: any) {
    return this.http.post(this.APIUrl + "/proposta/getbyid/", val);
  }


  insertProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/", val);
  }
  uploadProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/upload", val, {reportProgress: true, observe: 'events'});
  }
  updateProperty(val: any) {
    return this.http.put(this.APIUrl + "/propieta/", val);
  }
  deleteProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/delete/", val);
  }
  getProperty() {
    return this.http.get(this.APIUrl + "/propieta/");
  }
  getFilterProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/selectfilterproperty/", val);
  }
  getPropertyById(val: any) {
    return this.http.post(this.APIUrl + "/propieta/getbyid/", val);
  }




  insertArticoloCopy(val: any) {
    return this.http.post(this.APIUrl + "/articolocopy/", val);
  }
  updateArticoloCopy(val: any) {
    return this.http.post(this.APIUrl + "/articolocopy/update/", val);
  }
  deleteArticoloCopy(val: any) {
    return this.http.post(this.APIUrl + "/articolocopy/delete/", val);
  }
  getArticoloCopy() {
    return this.http.get(this.APIUrl + "/articolocopy/");
  }
  // getProposalById(val: any) {
  //   return this.http.post(this.APIUrl + "/proposta/getbyid/", val);
  // }


  insertArticolo(val: any) {
    return this.http.post(this.APIUrl + "/articolo/", val);
  }
  updateArticolo(val: any) {
    return this.http.post(this.APIUrl + "/articolo/update/", val);
  }
  deleteArticolo(val: any) {
    return this.http.post(this.APIUrl + "/articolo/delete/", val);
  }
  getArticolo() {
    return this.http.get(this.APIUrl + "/articolo/");
  }
  getArticoliByStampaId(val: any) {
    return this.http.post(this.APIUrl + "/articolo/getbyidstampa/", val);
  }

  insertStampa(val: any) {
    return this.http.post(this.APIUrl + "/stampa/", val);
  }
  updateStampa(val: any) {
    return this.http.post(this.APIUrl + "/stampa/update/", val);
  }
  deleteStampa(val: any) {
    return this.http.post(this.APIUrl + "/stampa/delete/", val);
  }
  getStampa() {
    return this.http.get(this.APIUrl + "/stampa/");
  }
  getStampaById(val: any) {
    return this.http.post(this.APIUrl + "/stampa/getbyid/", val);
  }
  
  insertLavorazione(val: Lavorazione) {
    return this.http.post(this.APIUrl + "/lavorazionenew/", val);
  }
  updateLavorazione(val: Lavorazione) {
    return this.http.post(this.APIUrl + "/lavorazionenew/update/", val);
  }
  deleteLavorazione(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionenew/delete/", val);
  }
  getLavorazione() {
    return this.http.get(this.APIUrl + "/lavorazionenew/");
  }
  getLavorazioneById(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionenew/getbyid/", val);
  }
  getLavorazioneByIdStampa(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionenew/getbyidstampa/", val);
  }
  getLavorazioneByIdAssistenza(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionenew/getbyidassistenza/", val);
  }

  insertLavorazioneCopy(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionecopy/", val);
  }
  updateLavorazioneCopy(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionecopy/update/", val);
  }
  deleteLavorazioneCopy(val: any) {
    return this.http.post(this.APIUrl + "/lavorazionecopy/delete/", val);
  }
  getLavorazioneCopy() {
    return this.http.get(this.APIUrl + "/lavorazionecopy/");
  }



  insertStampaNegozio(val: any) {
    return this.http.post(this.APIUrl + "/stampanegozio/", val);
  }
  updateStampaNegozio(val: any) {
    return this.http.post(this.APIUrl + "/stampanegozio/update/", val);
  }
  deleteStampaNegozio(val: any) {
    return this.http.post(this.APIUrl + "/stampanegozio/delete/", val);
  }
  getStampaNegozio() {
    return this.http.get(this.APIUrl + "/stampanegozio/");
  }
  getStampaByIdNegozio(val: any) {
    return this.http.post(this.APIUrl + "/stampanegozio/getbyid/", val);
  }


  insertCliente(val: Cliente) {
    return this.http.post(this.APIUrl + "/cliente/", val);
  }
  updateCliente(val: Cliente) {
    return this.http.post(this.APIUrl + "/cliente/update/", val);
  }
  deleteCliente(val: any) {
    return this.http.post(this.APIUrl + "/cliente/delete/", val);
  }
  getCliente() {
    return this.http.get(this.APIUrl + "/cliente/");
  }
  getClienteById(val: any) {
    return this.http.post(this.APIUrl + "/cliente/getbyid/", val)
  }
  getClienteStartingWith(val: any) {
    return this.http.post(this.APIUrl + "/cliente/getclientestartingwith/", val);
  }


  insertOrologio(val: Orologio) {
    return this.http.post(this.APIUrl + "/orologio/", val);
  }
  updateOrologio(val: Orologio) {
    return this.http.post(this.APIUrl + "/orologio/update/", val);
  }
  deleteOrologio(val: any) {
    return this.http.post(this.APIUrl + "/orologio/delete/", val);
  }
  getOrologio() {
    return this.http.get(this.APIUrl + "/orologio/");
  }
  getOrologioById(val: any) {
    return this.http.post(this.APIUrl + "/orologio/getbyid/", val)
  }
  getOrologioByIdAssistenza(val: any) {
    return this.http.post(this.APIUrl + "/orologio/getbyidassistenza/", val)
  }
  getOrologioStartingWith(val: any) {
    return this.http.post(this.APIUrl + "/orologio/getorologiostartingwith/", val);
  }


  insertOrdine(val: Ordine) {
    return this.http.post(this.APIUrl + "/ordine/", val);
  }
  updateOrdine(val: Ordine) {
    return this.http.post(this.APIUrl + "/ordine/update/", val);
  }
  deleteOrdine(val: any) {
    return this.http.post(this.APIUrl + "/ordine/delete/", val);
  }
  getOrdine() {
    return this.http.get(this.APIUrl + "/ordine/");
  }
  getOrdineById(val: any) {
    return this.http.post(this.APIUrl + "/ordine/getbyid/", val)
  }
  // getOrdineStartingWith(val: any) {
  //   return this.http.post(this.APIUrl + "/orologio/getorologiostartingwith/", val);
  // }
  uploadOrdini(val: any) {
    return this.http.post(this.APIUrl + "/ordini/upload", val, { reportProgress: true, observe: 'events' });
  }


  insertPreordine(val: Ordine) {
    return this.http.post(this.APIUrl + "/preordine/", val);
  }
  updatePreordine(val: Ordine) {
    return this.http.post(this.APIUrl + "/preordine/update/", val);
  }
  deletePreordine(val: any) {
    return this.http.post(this.APIUrl + "/preordine/delete/", val);
  }
  getPreordine() {
    return this.http.get(this.APIUrl + "/preordine/");
  }
  getPreordineById(val: any) {
    return this.http.post(this.APIUrl + "/preordine/getbyid/", val)
  }
  // getOrdineStartingWith(val: any) {
  //   return this.http.post(this.APIUrl + "/orologio/getorologiostartingwith/", val);
  // }
  uploadPreordini(val: any) {
    return this.http.post(this.APIUrl + "/preordine/upload", val, { reportProgress: true, observe: 'events' });
  }


  insertAssistenza(val: Assistenza) {
    return this.http.post(this.APIUrl + "/assistenza/", val);
  }
  updateAssistenza(val: Assistenza) {
    return this.http.post(this.APIUrl + "/assistenza/update/", val);
  }
  deleteAssistenza(val: any) {
    return this.http.post(this.APIUrl + "/assistenza/delete/", val);
  }
  getAssistenza() {
    return this.http.get(this.APIUrl + "/assistenza/");
  }
  getAssistenzaNums() {
    return this.http.get(this.APIUrl + "/assistenza/getnums/");
  }
  getAssistenzaById(val: any) {
    return this.http.post(this.APIUrl + "/assistenza/getbyid/", val)
  }
  getAssistenzaAllById(val: any) {
    return this.http.post(this.APIUrl + "/assistenza/getallbyid/", val)
  }


  insertIntervento(val: Intervento[]) {
    return this.http.post(this.APIUrl + "/intervento/", val);
  }
  updateIntervento(val: Intervento[]) {
    return this.http.post(this.APIUrl + "/intervento/update/", val);
  }
  deleteIntervento(val: any) {
    return this.http.post(this.APIUrl + "/intervento/delete/", val);
  }
  getIntervento() {
    return this.http.get(this.APIUrl + "/intervento/");
  }
  getInterventoById(val: any) {
    return this.http.post(this.APIUrl + "/intervento/getbyid/", val)
  }
  getInterventoByIdLavorazione(val: any) {
    return this.http.post(this.APIUrl + "/intervento/getbyidlavorazione/", val)
  }
  getInterventoByIdAssistenza(val: any) {
    return this.http.post(this.APIUrl + "/intervento/getbyidassistenza/", val)
  }

  getStorico(){
    return this.http.get(this.APIUrl + "/storico/");
  }

}
